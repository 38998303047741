import maplibregl from "maplibre-gl";
import AbstractMap from "./AbstractMap";
import MapListener from "./listener/MapListener";
import $ from "jquery";
import _ from "underscore";
import markerImg from "../../../../images/ics/marker.png";

export default class MapLibreMap extends AbstractMap {

    /**
     * Config
     *
     * @type {{default_zoom: number, listener: null, attribution: string, tile_server: string}}
     */
    config = {
        cluster_size: {
            1: 10,
            2: 50,
            3: 100
        },
        listener: null,
        default_zoom: 12,
        scrollZoom: true,
        flyToSpeed: 2,
        flyToMaxDuration: 3000,
        // access_token: 'pk.eyJ1Ijoic2ltcGx5Ym9va21lIiwiYSI6ImNreGFrNTQ5azEwb2QycnFjNHFoYms1b20ifQ.AaD8IeN0XivQWRLNpzZFVw',
        style: 'https://map2.simplybook.me/style.json'
        // static_user: 'simplybookme',
        // static_style: 'cjz2nrepw07sf1dp89r0uj8g6'
    };

    /**
     * Map listener
     *
     * @type {MapListener}
     */
    listener = null;

    /**
     * Map object
     *
     * @type {Map}
     */
    map = null;

    /**
     * Max zoom
     *
     * @type {int}
     */
    maxZoom = 18;

    /**
     * Map markers
     *
     * @type {Array}
     */
    markers = [];

    /**
     * Highlighted markers
     *
     * @type {Array}
     */
    highlightedMarkers = [];

    /**
     * Markers hash map
     *
     * @type {{}}
     */
    markersMap = {};

    /**
     * Constructor
     *
     * @param config
     */
    constructor(config = {}) {
        super();

        this.markers = [];

        if (config.listener) {
            this.listener = config.listener;
        } else {
            this.listener = new MapListener();
        }
        this.config = $.extend(this.config, config);
    }

    /**
     * @deprecated
     */
    getStaticMap(markers, size) {
        return;

        let map = 'https://api.mapbox.com/styles/v1/' + this.config.static_user + '/' + this.config.static_style + '/static/';
        let markersUrls = [];
        _.each(markers, (marker) => {
            markersUrls.push('url-' + encodeURIComponent(
                location.protocol + '//' + location.hostname + markerImg
            ) + '(' + marker.lng + ',' + marker.lat + ')');
        });

        let center = 'auto';
        if (markers.length == 1) {
            let marker = markers[0];

            center = marker.lng + ',' + marker.lat + ',15';
        }

        map += markersUrls.join(',') + '/' + center + '/' + size;

        return map;
    }

    /**
     * Attach map
     *
     * @param el
     * @param options
     */
    attachMap(el, options) {
        if (!el.attr('id')) {
            el.attr('id', 'map_box' + Math.random());
        }
        this.map = new maplibregl.Map({
            container: el.attr('id'),
            style: this.config.style,
            center: options.center ? options.center : [1.730610, -73.935242],
            zoom: options.zoom ? options.zoom : this.config.default_zoom,
            minZoom: 1,
            interactive: !options.inactive,
            scrollZoom: options.hasOwnProperty('scrollZoom') ? options.scrollZoom : this.config.scrollZoom
        });

        if (options.bound) {
            this.map.fitBounds([[options.bound.right_bottom.lng, options.bound.left_top.lat], [options.bound.left_top.lng, options.bound.right_bottom.lat]], {
                maxZoom: 14,
                minZoom: 1
            });
        }

        if (!options.inactive) {
            this.map.addControl(new maplibregl.NavigationControl({
                visualizePitch: true
            }));
        }

        this.map.on('moveend', () => {
            let bounds = this.map.getBounds();

            this.listener.onBoundsChanged(
                [
                    [bounds.getNorthWest().wrap().lat, bounds.getNorthWest().wrap().lng],
                    [bounds.getSouthEast().wrap().lat, bounds.getSouthEast().wrap().lng]
                ],
                Math.round(this.map.getZoom())
            );
        });
        this.map.on('click', this.listener.onMapClicked);

        this.map.fire('moveend');
    }

    /**
     * Clear all markers
     */
    clearMarkers() {
        _.each(this.markers, (marker) => {
            marker.remove();
        });
    }

    /**
     * Add marker
     *
     * @param lat
     * @param lng
     * @param id
     * @param data
     */
    addMarker(lat, lng, id, data) {
        let el = document.createElement('div');
        el.className = 'marker';

        let marker = new maplibregl.Marker(el, { offset: [0, -13] })
            .setLngLat([lng, lat])
            .addTo(this.map);

        el.addEventListener('click', (e) => {
            this.listener.onMarkerClicked(lat, lng, id, data);

            e.stopPropagation();
            return false;
        });

        this.markersMap[id] = marker;
        this.markers.push(marker);

        if (this.highlightedMarkers.indexOf(id) >= 0) {
            this.highlightMarker(id);
        }
    }

    /**
     * High light marker by id
     *
     * @param id
     */
    highlightMarker(id) {
        let result = false;
        let marker = this.markersMap[id];
        if (marker) {
            $(marker.getElement()).addClass('active');
            result = true;
        }

        if (this.highlightedMarkers.indexOf(id) === -1) {
            this.highlightedMarkers.push(id);
        }
        return result;
    }

    /**
     * Unhigh light marker by id
     *
     * @param id
     */
    unHighlightMarker(id) {
        let marker = this.markersMap[id];
        if (marker) {
            $(marker.getElement()).removeClass('active');
        }

        this.highlightedMarkers.splice(this.highlightedMarkers.indexOf(id), 1);
    }

    /**
     * Unhigh light markers
     */
    unHighlightMarkers() {
        _.each(this.highlightedMarkers, (id) => {
            let marker = this.markersMap[id];
            if (marker) {
                $(marker.getElement()).removeClass('active');
            }
        });
        this.highlightedMarkers = [];
    }

    /**
     * Add popup
     *
     * @param lat
     * @param lng
     * @param element
     */
    makePopup(lat, lng, element) {
        throw new Error('Please override makePopup method');
    }

    /**
     * Add cluster
     *
     * @param lat
     * @param lng
     * @param count
     */
    addCluster(lat, lng, count) {
        let el = document.createElement('div');
        el.className = 'cluster ' + this.getClusterSizeClass(count);
        el.textContent = count;

        let marker = new maplibregl.Marker(el, { offset: [0, 0] })
            .setLngLat([lng, lat])
            .addTo(this.map);

        el.addEventListener('click', (e) => {
            this.map.flyTo({
                center: [lng, lat],
                zoom: this.map.getZoom() + 2
            });

            e.stopPropagation();
            return false;
        });

        this.markers.push(marker);
    }

    flyTo(lng, lat) {
        this.map.flyTo({
            center: [lng, lat],
            zoom: this.map.getZoom() < 14 ? 14 : this.map.getZoom(),
            maxDuration: this.config.flyToMaxDuration,
            speed: this.config.flyToSpeed
        });
    }

    zoomTo(zoom) {
        this.map.zoomTo(zoom);
    }

    /**
     * Invalidate size of map
     */
    invalidateSize() {
        this.map.resize();
    }

    /**
     * Return max zoom for this map type
     */
    getMaxZoom() {
        return 15;
    }

    /**
     * Fit bounds to markers
     */
    fitBounds() {
        let max = {lat: null, lng: null};
        let min = {lat: null, lng: null};
        _.each(this.markers, (marker) => {
            let geometry = marker.getLngLat();

            if (max.lat === null || max.lat < geometry.lat) {
                max.lat = geometry.lat;
            }
            if (min.lat === null || min.lat > geometry.lat) {
                min.lat = geometry.lat;
            }
            if (max.lng === null || max.lng < geometry.lng) {
                max.lng = geometry.lng;
            }
            if (min.lng === null || min.lng > geometry.lng) {
                min.lng = geometry.lng;
            }
        });
        this.map.fitBounds([[min.lng, min.lat], [max.lng, max.lat]], {
            maxZoom: 14,
            minZoom: 1,
        });
    }

    /**
     * Return size class for cluster
     *
     * @param count
     */
    getClusterSizeClass(count) {
        if (count < this.config.cluster_size[1]) {
            return 'sz-1';
        }
        if (count < this.config.cluster_size[2]) {
            return 'sz-2';
        }
        if (count < this.config.cluster_size[3]) {
            return 'sz-3';
        }
        return 'sz-4';
    }

}