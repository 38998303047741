import Marionette from "backbone.marionette";
import _ from "underscore";

let Paginator = Marionette.Object.extend({

    page: 1,
    onPage: 10,
    pagesCount: 1,
    data: {},

    getPage: function () {
        return this.page;
    },

    setRequestData: function (data) {
        this.trigger('before:change:filter');

        this.data = data;
        this.page = 1;

        this.trigger('change');
        this.trigger('change:filter');
        this.trigger('update:info');
    },

    getRequestData: function () {
        return this.data;
    },

    setPage: function (page) {
        this.page = parseInt(page);

        this.trigger('change');
        this.trigger('change:page');
        this.trigger('update:info');

        return this;
    },

    nextPage() {
        this.setPage(this.page + 1);

        this.trigger('next:page');
    },

    getOnPage: function () {
        return this.onPage;
    },

    setOnPage: function (onPage) {
        this.onPage = parseInt(onPage);

        this.trigger('change');
        this.trigger('change:page');
        this.trigger('update:info');

        return this;
    },

    getPagesCount: function () {
        return this.pagesCount;
    },

    setPagesCount: function (pagesCount) {
        this.pagesCount = parseInt(pagesCount);

        this.trigger('update:info');
        this.trigger('update:info:count');

        return this;
    },

    getData: function () {
        return _.extend({
            'page': this.getPage(),
            'on_page': this.getOnPage(),
            'pages_count': this.getPagesCount()
        }, this.data);
    },

    getPagesData: function (displayPagesCount) {
        if (!displayPagesCount) {
            displayPagesCount = 3;
        }

        displayPagesCount = Math.min(this.getPagesCount(), displayPagesCount);
        let pages = [];

        for (let i = 0; i <= displayPagesCount; i++) {
            if ((this.getPage() - i) > 0) {
                pages.push(this.getPage() - i);
            }
            if ((this.getPage() + i) <= this.getPagesCount()) {
                pages.push(this.getPage() + i);
            }
        }

        pages = pages.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
        pages.sort((a, b) => {
            return a - b;
        });

        return {
            'pages': pages,
            'prev': this.getPage() > 1 ? this.getPage() - 1 : null,
            'next': this.getPage() < this.getPagesCount() ? this.getPage() + 1 : null,
            'last': Math.max.apply(null, pages) < this.getPagesCount() ? this.getPagesCount() : null,
            'first': Math.min.apply(null, pages) > 1 ? 1 : null,
            'current': this.getPage()
        };
    }

});

export default Paginator;