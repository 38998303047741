import {Application} from "backbone.marionette";
import TopCompanyMapController from "./controller/TopCompanyMapController";
import CurrencyFormatter from "../../app/library/marionette/lib/CurrencyFormatter";

const TopCompanyMapModuleApp = Application.extend({

    mapController: null,

    onStart() {
        this.mapController = new TopCompanyMapController({
            paths: this.getOption('paths'),
            data: this.getOption('data'),
            center: this.getOption('center'),
            zoom: this.getOption('zoom'),
            locationName: this.getOption('location_name'),
            categoryName: this.getOption('category_name')
        });

        this.mapController.setFilter(this.getOption('data'));

        CurrencyFormatter.getInstance().init({url: this.getOption('paths').currency_end_point}).then(() => {
            this.mapController.start();
        });
    }

});

export default TopCompanyMapModuleApp;