import {Application} from "backbone.marionette";
import SearchFilterController from "./controller/SearchFilterController";
import CurrencyFormatter from "../../app/library/marionette/lib/CurrencyFormatter";

const SearchFilterModuleApp = Application.extend({

    filterController: null,

    onStart() {
        CurrencyFormatter.getInstance().init({url: this.getOption('paths').currency_end_point}).then(() => {
            this.filterController = new SearchFilterController({
                paths: this.getOption('paths'),
                data: this.getOption('data')
            });

            this.filterController.start();
        });
    }

});

export default SearchFilterModuleApp;