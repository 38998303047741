import BaseModel from "../../../app/library/marionette/model/BaseModel";

const CompanyModel = BaseModel.extend({

    idAttribute: 'login',

    defaults: {

    }

});

export default CompanyModel;