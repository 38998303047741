export default class TimeFilterUi {

    inputFrom = null;
    inputTo = null;
    slider = null;
    label = null;
    clearBtn = null;

    constructor(inputFrom, inputTo, slider, label, clearBtn) {
        this.inputFrom = inputFrom;
        this.inputTo = inputTo;
        this.slider = slider;
        this.label = label;
        this.clearBtn = clearBtn;

        if (this.inputFrom.data('val') === '') {
            this.inputFrom.val('');
        }
        if (this.inputTo.data('val') === '') {
            this.inputTo.val('');
        }

        this.slider.ionRangeSlider({
            type: 'double',
            min: 0,
            max: 24,
            skin: 'round',
            hide_min_max: true,
            prettify: (val) => {
                return TimeFilterUi.formatHour(val);
            },
            onChange: (e) => this.sliderHandler(e)
        });

        this.initEvents();
        this.selectHandler();
    }

    sliderHandler(event) {
        if (parseInt(event.from) === 0 && parseInt(event.to) === 24) {
            this.inputFrom.val('');
            this.inputTo.val('');
        } else {
            this.inputFrom.val(TimeFilterUi.formatHour(event.from));
            this.inputTo.val(TimeFilterUi.formatHour(event.to));
        }

        this.updateTitle();
    }

    selectHandler() {
        if (this.inputFrom.val() !== null && this.inputTo.val() !== null) {
            this.slider.data("ionRangeSlider").update({
                from: parseInt(this.inputFrom.val()),
                to: parseInt(this.inputTo.val())
            });
        } else {
            this.slider.data("ionRangeSlider").update({
                from: 0,
                to: 24
            });
        }

        this.updateTitle();
    }

    initEvents() {
        this.inputFrom.change(() => {
            if (parseInt(this.inputFrom.val()) > parseInt(this.inputTo.val())) {
                this.inputTo.val(this.inputFrom.val());
            }
            this.selectHandler();
        });
        this.inputTo.change(() => {
            if (parseInt(this.inputTo.val()) < parseInt(this.inputFrom.val())) {
                this.inputFrom.val(this.inputTo.val());
            }
            this.selectHandler()
        });
        this.clearBtn.click(() => {
            this.inputFrom.val('');
            this.inputTo.val('');
            this.selectHandler();

            return false;
        });
        $(document).click((event) => {
            if ($(event.target).closest('.collapse').length === 0) {
                this.clearBtn.closest('.collapse').collapse('hide');
            }
        });
    }

    updateTitle() {
        if (!this.inputFrom.val() && !this.inputTo.val()) {
            this.label.removeClass('active').text('Any time');
        } else {
            let from = this.inputFrom.val() ? this.inputFrom.val() : TimeFilterUi.formatHour(0);
            let to = this.inputTo.val() ? this.inputTo.val() : TimeFilterUi.formatHour(24);

            this.label.addClass('active').text(from + ' - ' + to);
        }
    }

    static formatHour(val) {
        if (val < 10) {
            val = '0' + val;
        }
        return val + ':00';
    }

}