import BookingWidget from "./BookingWidget";
import Factory from "../app/library/map/Factory";
import 'magnific-popup';

export default class CompanyPage {

    /**
     * @type {LeafletMap|null}
     */
    map = null;

    mapLoaded = false;

    config = {
        map_center: [],
        map_markers: []
    };

    showMoreCompanyPromotionsGroupElement = "#show_more_company_promotions_group";
    showMoreCompanyUnitsGroupElement = '#show_more_company_units_group';
    showMoreCompanyServicesGroupElement = '#show_more_company_services_group';
    showMoreGalleryImagesGroupElement = '#show_more_gallery_images_group';
    companyGalleryWrapper = '#company-gallery';

    constructor(config = {}) {
        this.config = $.extend({}, this.config, config);

        this.initMap();
        this.showMoreCompanyPromotions();
        this.showMoreCompanyUnits();
        this.showMoreCompanyServices();
        this.showMoreGalleryImages();
        this.companyGalleryModal();
    };

    initMap() {
        this.map = Factory.getMap();

        this.loadMap();

        $('#app_map_popup_open, #app_map_popup_open_mobile').on("click", () => {
            this.loadMap();

            $('#map_container').addClass('fullscreen');
            $('body').addClass('modal-open');
            $('#dr_overlay').addClass('show');

            if (navigator.appVersion.indexOf("Mac")!=-1) {
                $('body').css('padding-right', '');
            } else  {
                $('body').css('padding-right', '17px');
            }

            this.map.invalidateSize();
            this.map.fitBounds();
        });

        $('#map_popup_close, #dr_overlay').on("click", () => {
            $('#map_container').removeClass('fullscreen');
            $('body').removeClass('modal-open').css('padding-right', '');
            $('#dr_overlay').removeClass('show');

            this.map.invalidateSize();
            this.map.fitBounds();
        });
    };

    loadMap () {
        if (!this.mapLoaded) {
            $('#map img').remove();

            this.map.attachMap($('#map'), {
                center: this.config.map_center,
                zoom: 16
            });

            this.config.map_markers.forEach((marker) => {
                this.map.addMarker(marker.lat, marker.lng);
            });
            this.map.fitBounds();

            this.mapLoaded = true;
        }
    }

    showMoreCompanyPromotions() {
        $(this.showMoreCompanyPromotionsGroupElement).on("click", () => {
            let nextCompanyPromotionsGroup = $(this.showMoreCompanyPromotionsGroupElement).data('next-company-promotions-group');
            let maxCompanyPromotionsGroup = $(this.showMoreCompanyPromotionsGroupElement).data('max-company-promotions-group');

            $('.company-promotions-list-' + nextCompanyPromotionsGroup).removeClass('d-none');

            if (nextCompanyPromotionsGroup === maxCompanyPromotionsGroup) {
                $(this.showMoreCompanyPromotionsGroupElement).addClass('d-none');
            }

            $(this.showMoreCompanyPromotionsGroupElement).data('next-company-units-group', nextCompanyPromotionsGroup + 1)
        });
    };

    showMoreCompanyUnits() {
        $(this.showMoreCompanyUnitsGroupElement).on("click", () => {
            let nextCompanyUnitsGroup = $(this.showMoreCompanyUnitsGroupElement).data('next-company-units-group');
            let maxCompanyUnitsGroup = $(this.showMoreCompanyUnitsGroupElement).data('max-company-units-group');

            $('.units-group-list-' + nextCompanyUnitsGroup).removeClass('d-none');

            if (nextCompanyUnitsGroup === maxCompanyUnitsGroup) {
                $(this.showMoreCompanyUnitsGroupElement).addClass('d-none');
            }

            $(this.showMoreCompanyUnitsGroupElement).data('next-company-units-group', nextCompanyUnitsGroup + 1)
        });
    };

    showMoreCompanyServices() {
        $(this.showMoreCompanyServicesGroupElement).on("click", () => {
            let nextCompanyServicesGroup = $(this.showMoreCompanyServicesGroupElement).data('next-company-services-group');
            let maxCompanyServicesGroup = $(this.showMoreCompanyServicesGroupElement).data('max-company-services-group');

            $('.services-group-list-' + nextCompanyServicesGroup).removeClass('d-none');

            if (nextCompanyServicesGroup === maxCompanyServicesGroup) {
                $(this.showMoreCompanyServicesGroupElement).addClass('d-none');
            }

            $(this.showMoreCompanyServicesGroupElement).data('next-company-services-group', nextCompanyServicesGroup + 1)
        });
    };

    showMoreGalleryImages() {
        $(this.showMoreGalleryImagesGroupElement).on("click", () => {
            let nextGalleryImagesGroup = $(this.showMoreGalleryImagesGroupElement).data('next-gallery-images-group');
            let maxGalleryImagesGroup = $(this.showMoreGalleryImagesGroupElement).data('max-gallery-images-group');

            $('.gallery-images-list-' + nextGalleryImagesGroup).removeClass('d-none');

            if (nextGalleryImagesGroup === maxGalleryImagesGroup) {
                $(this.showMoreGalleryImagesGroupElement).addClass('d-none');
            }

            $(this.showMoreGalleryImagesGroupElement).data('next-gallery-images-group', nextGalleryImagesGroup + 1)
        });
    };

    companyGalleryModal() {
        $(this.companyGalleryWrapper).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery:{
                enabled:true
            },
            image: {
                markup: '<div class="mfp-figure">'+
                    '<div class="mfp-bottom-bar">'+
                    '<div class="mfp-title"></div>'+
                    '<div class="mfp-counter"></div>'+
                    '<div class="mfp-close"></div>'+
                    '</div>'+
                    '<div class="mfp-img"></div>'+
                    '</div>',
                cursor: 'mfp-default-cur',
                titleSrc: function(item) {
                      return item.el.attr('alt');
                    }
            },
            callbacks: {
                buildControls: function() {
                    this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
                }

            }
        });
    };
}