import CompanyModel from "../model/CompanyModel";
import BaseCollection from "../../../app/library/marionette/collection/BaseCollection";
import Paginator from "../../../app/library/marionette/collection/Paginator";

const CompanyCollection = BaseCollection.extend({

    model: CompanyModel,
    paginator: null,
    total: 0,

    initialize: function (items, options) {
        this.url = options.url;
        this.paginator = new Paginator();

        this.listenTo(this.paginator, 'change:filter', this.reloadCollection);
        this.listenTo(this.paginator, 'next:page', this.loadNextPage);
    },

    reloadCollection: function () {
        if (this.getPaginator().getPage() <= 0) {
            return;
        }

        this.fetch({
            data: this.getPaginator().getData()
        });
    },

    loadNextPage: function () {
        this.fetch({
            data: this.getPaginator().getData(),
            remove: false
        });
    },

    getPaginator: function () {
        return this.paginator;
    },

    getTotal: function () {
        return this.total;
    },

    parse: function (response) {
        this.paginator.setPagesCount(response.meta.pages_count);
        this.total = response.meta.count;

        return response.data;
    }

});

export default CompanyCollection;