import SearchFilterModuleView from "../view/SearchFilterModuleView";
import BaseFilterController from "./base/BaseFilterController";

const SearchFilterController = BaseFilterController.extend({

    initViews: function () {
        this.region.show(new SearchFilterModuleView({
            collection: this.collection,
            client_location: this.getOption('data').client_location
        }));
    },

    initModels: function () {
        return Promise.all([this.collection.load({
            data: $.extend({}, this.getOption('data').filter, this.getOption('data').selected_location)
        })]);
    },

});

export default SearchFilterController;