const $ = require('jquery');

import "bootstrap";
import "bootstrap-datepicker";
import "ion-rangeslider";
import "lazysizes";
import "intl";

import SearchFilterModuleApp from "./module/filter.module/SearchFilterModuleApp";
import MapFilterModuleApp from "./module/filter.module/MapFilterModuleApp";
import TopCompanyMapModuleApp from "./module/top-company-map.module/TopCompanyMapModuleApp";
import ReviewsModuleApp from "./module/reviews.module/ReviewsModuleApp";

import CompanyLocationSelectUi from "./dom.ui/company/CompanyLocationSelect";
import CategorySelectUi from "./dom.ui/CategorySelect";
import LocationSelectUi from "./dom.ui/LocationSelect";
import MapboxLocationSelectUi from "./dom.ui/MapboxLocationSelect";

import SearchIndexPage from "./dom.ui/SearchIndexPage";
import CompanyPage from "./dom.ui/CompanyPage";
import BookingWidget from "./dom.ui/BookingWidget";
import CompanyShowOnMap from "./dom.ui/CompanyShowOnMap";

import MapFactory from "./app/library/map/Factory";

global.SearchFilterModuleApp = SearchFilterModuleApp;
global.MapFilterModuleApp = MapFilterModuleApp;
global.TopCompanyMapModuleApp = TopCompanyMapModuleApp;
global.ReviewsModuleApp = ReviewsModuleApp;
global.simpleInactiveMap = simpleInactiveMap;

global.CompanyLocationSelectUi = CompanyLocationSelectUi;
global.CategorySelectUi = CategorySelectUi;
global.LocationSelectUi = LocationSelectUi;
global.MapboxLocationSelectUi = MapboxLocationSelectUi;

global.SearchIndexPage = SearchIndexPage;
global.CompanyPage = CompanyPage;
global.BookingWidget = BookingWidget;

global.CompanyShowOnMap = CompanyShowOnMap;

import 'owl.carousel';

function carouselCategories() {
    $('.home-page__category-carousel').owlCarousel({
        items: 1,
        lazyLoad: true,
        loop: true,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        autoHeight: true
    });
}

carouselCategories();


function carouselReviews() {
    $('.reviews-carousel').owlCarousel({
        items: 1,
        lazyLoad: true,
        loop: true,
        dots: true,
        autoHeight: true,
        nav: true
    });
}

carouselReviews();

function toggleHeaderMenu() {
    $('.header__menu-button-togg').on('click', function () {
        $('body, html').addClass('header-menu-in');
        $('.header__nav-wrapper').toggleClass('is-open');
        $('.header__menu-cover').toggleClass('in');
        return false;
    });

    $(document).on('click', [
        '.header__menu .header__nav-link',
        '.header__menu-cover'
    ].join(', '), function () {
        $('body, html').removeClass('header-menu-in');
        $('.header__nav-wrapper').removeClass('is-open');
        $('.header__menu-cover').removeClass('in');
    });

    $(document).on("click", function (e) {
        if ($('.header__nav-wrapper').hasClass('is-open') && $(e.target).closest('.header__nav-wrapper').length == 0) {
            $('body, html').removeClass('header-menu-in');
            $('.header__nav-wrapper').removeClass('is-open');
            $('.header__menu-cover').removeClass('in');
            e.preventDefault();
        }
    });
}

toggleHeaderMenu();

carouselReviews();

function scrollTop() {
    $('.footer__scroll-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        ``
    });
}

scrollTop();
//
// function removeRedBorders() {
//     $('*').css('border', '');
// }
//
// removeRedBorders();

//for about page
$('#video').on('click', function () {
    $(this)[0].play()
});

function smoothScrollAnchorLinks() {
    $('.anchor-link').click(function (e) {
        e.preventDefault();
        var target = $($(this).attr('href'));
        if (target.length) {
            var scrollTo = target.offset().top;
            $('body, html').animate({scrollTop: scrollTo + 'px'}, 800);
        }
    });
}

smoothScrollAnchorLinks();

function simpleInactiveMap(el, lat, lng, zoom) {
    let params = {
        center: {lat: lat, lng: lng},
        inactive: true,
        zoom: zoom
    };

    let id = Math.ceil(Math.random() * 100000);
    let map = MapFactory.getMap();

    map.attachMap($(el), params);
    map.addMarker(lat, lng, id, id.toString());
}

$(window).resize(function () {
    recalculateCategories(); // maybe use debounce here
});
recalculateCategories();

function recalculateCategories() {

    let $categoriesList = $('.dr-category__list');
    let $categoriesMoreButton = $('.dr-category__more');
    let $categoriesItem = $('.dr-category__list .dr-category__item');
    let $categoriesItemSub = $('.dr-category__list-dropdown .dr-category__item');

    $categoriesMoreButton.hide();
    $categoriesItemSub.hide();
    $categoriesItem.show();

    do {
        var childrenWidth = 0;
        $categoriesList.children().filter(':visible').each(function () {
            childrenWidth += $(this).outerWidth();
        });

        // NB use extra 10 pixels for padding etc.
        if ($categoriesList.width() > childrenWidth - 10) break;

        var $lastVisibleButton = $categoriesItem.filter(':visible').last();
        $categoriesMoreButton.show();
        $categoriesItemSub.filter('[data-index=' + $lastVisibleButton.data('index') + ']').show();
        $lastVisibleButton.hide();

    } while ($categoriesItem.filter(':visible').length);
}

function categoriesToggler() {
    let $categoriesMoreButton = $('.dr-category__more');
    let $categoriesSublist = $('.dr-category__list-dropdown');

    $categoriesMoreButton.click(function () {
        $(this).toggleClass('active');
        $categoriesSublist.toggleClass('show');
    });
}

categoriesToggler();

function coverInCollapseContent() {
    var $pageOverlay = $('.page-overlay');

    $(document).on('show.bs.collapse', function (e) {
        var $clickTarget = $(e.target);
        if ($clickTarget.hasClass('filters__select-dropdown')) {
            $('.show-header-on-map-view').css({'zIndex': 100});
            $clickTarget.closest('.filters__item').addClass('in');
            $pageOverlay.addClass('show');
        }
    });
    $(document).on('hidden.bs.collapse', function (e) {
        var $filtersItem = $('.filters__item');
        $pageOverlay.removeClass('show');
        $filtersItem.removeClass('in');
        $('.show-header-on-map-view').removeAttr('style');
    });
}

coverInCollapseContent();

function headerOnMapView() {
    var $filters_show = $('.filters__show'),
        $map_view = $('.map-view');

    $filters_show.on('click', function () {
        $(this).toggleClass('active');
        $map_view.toggleClass('in');
        $('#app_map_container .app-map.mapboxgl-map').toggleClass('in');
    });
}

headerOnMapView();

function showNewSelectView() {
    let togHeaderSearchForm = $('.header.new-select-view .tog-header-search-form');
    let newViewHeaderSearchForm = $('.header__search-form');

    if (togHeaderSearchForm.length > 0) {
        togHeaderSearchForm.on('click', function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                newViewHeaderSearchForm.slideUp('slow', function () {
                    newViewHeaderSearchForm.attr('style', '');
                });
            } else {
                $(this).addClass('active');
                newViewHeaderSearchForm.slideDown('slow', function () {
                    newViewHeaderSearchForm.css({'display': 'flex'});
                });
            }
        });
    }
}
showNewSelectView();

function owlCarouselAccessibility() {
    $('.owl-carousel').each(function () {
        $(this).find('.owl-dot').each(function (index) {
            $(this).attr('aria-label', index + 1);
        });
    });
}

owlCarouselAccessibility();


function headerLanguageSelector() {

    var $width = $(window).width();

    var $pageOverlay = $('.page-overlay');

    //if ($width < 769) {
        $(document).on('show.bs.collapse', function (e) {
            var $clickTarget = $(e.target);
            if ($clickTarget.hasClass('header__lang-list')) {
                $('.header__lang').addClass('opened');
                $pageOverlay.addClass('show');
            }
        });

        $(document).on('hidden.bs.collapse', function () {
            $pageOverlay.removeClass('show');
            $('.header__lang').removeClass('opened');
        });

        $pageOverlay.click(function () {
            $('.header__lang-list').collapse('hide');
        });
    //}

    $(document).mouseup(function (e) {
        var $languagesList = $(".header__lang-list");

        if (!$languagesList.is(e.target) && $languagesList.has(e.target).length === 0) {
            $languagesList.collapse('hide');
        }
    });

}

headerLanguageSelector();

$(document).on('click', '.service__body-modal', function (event) {
    if ($(this).data('url')) {
        location.href = $(this).data('url');

        event.stopPropagation();
        return false;
    }
});