import _template from "../../../app/library/_template";
import _ from "underscore";
import {View} from "backbone.marionette";


const TopCompanyMapCompanyItemView = View.extend({

    template: _template('#app_top_company_map_company_item_view'),

    serializeData() {
        return _.extend(View.prototype.serializeData.apply(this, arguments), {
            base_company_url: this.getOption('companyBaseUrl'),
            base_company_page_url: this.getOption('companyPageUrl'),
            base_company_book_url: this.getOption('companyBookUrl'),
            img_domain: this.getOption('imgDomain')
        });
    }
});

export default TopCompanyMapCompanyItemView;