import Handlebars from "handlebars/dist/handlebars";

Handlebars.registerPartial('filter_date', $('#app_filter_date_partial_template').html());
Handlebars.registerPartial('filter_time', $('#app_filter_time_partial_template').html());
Handlebars.registerPartial('filter_text', $('#app_filter_text_partial_template').html());
Handlebars.registerPartial('filter_hidden', $('#app_filter_hidden_partial_template').html());
Handlebars.registerPartial('filter_slider', $('#app_filter_slider_partial_template').html());
Handlebars.registerPartial('filter_price', $('#app_filter_price_partial_template').html());
Handlebars.registerPartial('filter_select', $('#app_filter_select_partial_template').html());
Handlebars.registerPartial('filter_multiselect', $('#app_filter_multiselect_partial_template').html());
Handlebars.registerPartial('filter_checkbox', $('#app_filter_checkbox_partial_template').html());
Handlebars.registerPartial('filter_link_select', $('#app_filter_link_select_partial_template').html());