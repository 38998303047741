export default class BookingWidget {

    iframe = null;

    constructor(el) {
        this.iframe = $(el);

        this.initEvents();
    }

    initEvents() {
        let _this = this;
        window.addEventListener("message", function (event) {
            if (event.data && typeof(event.data) == 'object') {
                switch (event.data.event) {
                    case 'updateWidgetSize':
                        _this.updateWidgetSize(event.data);
                        break;
                }
            }
        }, false);
    }

    updateWidgetSize(data) {
        this.iframe.height(data.height);
    }

}