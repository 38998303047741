import _template from "../app/library/_template";
import _ from "underscore";

export default class MapboxLocationSelectUi {

    template = _template('#app_ui_mapbox_location_select');

    geocoder = null;

    el = null;
    input = null;

    location = {};
    result = [];
    searchText = '';
    selectedIndex = null;

    /** @todo inject it */
    accessToken = 'pk.eyJ1Ijoic2ltcGx5Ym9va21lIiwiYSI6ImNreGFrNTQ5azEwb2QycnFjNHFoYms1b20ifQ.AaD8IeN0XivQWRLNpzZFVw';
    endPoint = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
    animationDuration = 200;

    constructor(el, config) {
        this.el = $(el);
        this.location = config.location || {};
        this.config = config;

        this.initDom();
        this.initEvents();
        this.detectLocation();
    }

    detectLocation() {
        if (!this.config.main_selector) {
            return;
        }
        if (!this.location.selected_by_client && this.location.point && !this.location.bounding) {
            this.updateLocationByPoint(this.location.point.lat, this.location.point.lng);
        }
        if (!this.location.selected_by_client && ("geolocation" in navigator)) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (this.location && this.location.client_point && this.location.bounding &&
                    this.location.client_point.lat === position.coords.latitude &&
                    this.location.client_point.lng === position.coords.longitude) {

                    return;
                }

                this.el.find('.app-c-lng-input').val(position.coords.longitude);
                this.el.find('.app-c-lat-input').val(position.coords.latitude);

                this.updateLocationByPoint(position.coords.latitude, position.coords.longitude);
            });
        }
    }

    updateLocationByPoint(lat, lng) {
        let params = {
            access_token: this.accessToken,
            types: 'place,region,country,postcode',
        };
        let url = this.endPoint + lng + ',' + lat + '.json?' + $.param(params);

        $.getJSON(url, (res) => {
            this.updateLocation(res.features[0], false);
            this.sendToServer(false);
        });
    }

    sendToServer(selectedByClient) {
        let data = this.el.find('form').serializeArray();
        if (selectedByClient) {
            data.push({name: 'selected_by_client', value: true});
        }
        $.post(this.config.update_location_end_point, data, (loc) => {
            this.location = loc;
        });
    }

    initDom() {
        this.container = this.el.find('.app-search-container');
        this.input = this.el.find('.app-mapbox-input');
        this.hideMobPopup = this.el.find('.close-mob-popup');

        this.form = this.el.find('.app-mapbox-form');

        this.buttonMobile = this.el.find('.app-location-select-mobile');

        this.changeBtn = this.el.find('.app-change-location');
        this.confirmBtn = this.el.find('.app-confirm-location');

        this.dialog = this.el.find('.app-confirm-location-dialog');

        this.searchText = this.input.val();

        this.render();
    }

    initEvents() {
        let _this = this;
        const $pageOverlay = $('#dr_overlay');
        $(document).on('click',  function (e) {
            if( $(e.target).hasClass('page-overlay') || $(e.target).hasClass('close-mob-popup') ){
                e.preventDefault();
                _this.container.removeClass('show');
                _this.form.removeClass('opened');
                $pageOverlay.removeClass('show');
                _this.input.closest('#app_mapbox_location_select_main').removeClass('active');
            }
        });

        this.input.focus(() => {
            this.input.select();
            this.input.closest('#app_mapbox_location_select_main').addClass('active');
            this.container.addClass('show');
            if (this.searchText.length && this.result.length === 0) {
                this.search();
            }
            $pageOverlay.addClass('show');
        });
        $(document).click((e) => {
            if (!$(e.target).closest(this.el).length) {
                this.container.removeClass('show');
                this.form.removeClass('opened');
            }
        });

        this.buttonMobile.click(() => {
            this.form.addClass('opened');
            this.input.focus();
            $pageOverlay.addClass('show');
        });

        this.input.keyup(_.debounce(_.bind(this.onChange, this), 200));
        this.input.keydown((e) => {
            switch(e.which) {
                case 38:
                    this.focusPrev();
                    return false;
                case 40:
                    this.focusNext();
                    return false;
                case 13:
                    this.submitSelected();
                    return false;
            }
        });
        this.changeBtn.click(() => {
            this.dialog.fadeOut(200, () => {
                this.searchText = '';
                this.render();

                this.input.focus();
            });
        });
        this.confirmBtn.click(() => {
            this.dialog.fadeOut(200);
            this.sendToServer(true);

            return false;
        });
    }

    focusPrev() {
        if (this.selectedIndex === null || this.selectedIndex === 0) {
            this.selectedIndex = this.result.length - 1;
        } else {
            this.selectedIndex--;
        }
        this.render();
    }

    focusNext() {
        if (this.selectedIndex === null || this.selectedIndex === this.result.length - 1) {
            this.selectedIndex = 0;
        } else {
            this.selectedIndex++;
        }
        this.render();
    }

    submitSelected() {
        if (this.selectedIndex !== null) {
            this.updateLocation(this.result[this.selectedIndex], true);
        }
    }

    onChange() {
        if (this.input.val() !== this.searchText) {
            this.searchText = this.input.val();
            this.search();
        }
    }

    search() {
        this.selectedIndex = null;
        if (this.searchText.length === 0) {
            this.result = [];
            this.render();

            return;
        }

        let data = {
            access_token: this.accessToken,
            types: 'place,region,country,postcode',
            limit: 10
        };
        if (this.location && this.location.client_point) {
            data.proximity = this.location.client_point.lng + ',' + this.location.client_point.lat
        }
        let url = this.endPoint + encodeURI(this.searchText) + '.json?' + $.param(data);

        $.getJSON(url, (data) => {
            this.result = data.features;
            this.render();
        });
    }

    render() {
        this.container.html(this.template({
            data: this.result,
            selectedId: this.selectedIndex === null ? null : this.result[this.selectedIndex].id,
            searchText: this.searchText,
            hasText: this.searchText.length > 0,
            emptyLocationEndPoint: this.config.empty_location_end_point,
            updateLocationByIdEndPoint: this.config.update_location_by_id_end_point
        }));
        this.initRenderedItemsEvents();

        let cont = this.container.find('.app-list-container');
        let item = this.container.find('.app-item.active');
        if (item.length) {
            cont.scrollTop(cont.scrollTop() + item.position().top - cont.height()/2 + item.height()/2);
        }
    }

    updateLocation(data, submit) {
        this.input.val(data.text).blur();
        this.searchText = data.text;

        let country = '';
        _.each(data.context, (item) => {
            if (item.id.indexOf('country') === 0) {
                country = item.short_code.toUpperCase();
            }
        });

        this.el.find('.app-country-input').val(country);

        this.el.find('.app-name-input').val(data.text);

        this.el.find('.app-lat-input').val(data.center[1]);
        this.el.find('.app-lng-input').val(data.center[0]);

        let ltLat, ltLng, rbLat, rbLng;
        if (data.bbox) {
            ltLat = data.bbox[1];
            ltLng = data.bbox[0];
            rbLat = data.bbox[3];
            rbLng = data.bbox[2];
        } else {
            let box = this.getBoundingBox(data.center[1], data.center[0], 0.1);
            ltLat = box[1];
            ltLng = box[0];
            rbLat = box[3];
            rbLng = box[2];
        }

        this.el.find('.app-lt-lat-input').val(ltLat);
        this.el.find('.app-lt-lng-input').val(ltLng);
        this.el.find('.app-rb-lat-input').val(rbLat);
        this.el.find('.app-rb-lng-input').val(rbLng);

        this.el.find('.app-place-input').val(data.id);

        if (submit) {
            this.el.find('form').submit();
        }
    }

    initRenderedItemsEvents() {
        this.container.find('.app-item-link').click((item) => {
            let data = _.findWhere(this.result, {id: $(item.target).data('id')});
            this.updateLocation(data, true);

            return false;
        });
        this.container.find('.app-empty-link').click(() => {
            this.input.val('').blur();
            this.searchText = '';

            this.el.find('.app-name-input').val('');
            this.el.find('.app-lat-input').val('');
            this.el.find('.app-lng-input').val('');
            this.el.find('.app-lt-lat-input').val('');
            this.el.find('.app-lt-lng-input').val('');
            this.el.find('.app-rb-lat-input').val('');
            this.el.find('.app-rb-lng-input').val('');
            this.el.find('.app-place-input').val('');

            this.el.find('form').submit();

            return false;
        });
    }

    getBoundingBox(degLat, degLon, distance) {
        let MIN_LAT, MAX_LAT, MIN_LON, MAX_LON, R, radDist, radLat, radLon, minLat, maxLat, minLon, maxLon, deltaLon;
        if (distance < 0) {
            return 'Illegal arguments';
        }
        // helper functions (degrees<–>radians)
        Number.prototype.degToRad = function () {
            return this * (Math.PI / 180);
        };
        Number.prototype.radToDeg = function () {
            return (180 * this) / Math.PI;
        };
        // coordinate limits
        MIN_LAT = (-90).degToRad();
        MAX_LAT = (90).degToRad();
        MIN_LON = (-180).degToRad();
        MAX_LON = (180).degToRad();
        // Earth's radius (km)
        R = 6378.1;
        // angular distance in radians on a great circle
        radDist = distance / R;
        // center point coordinates (rad)
        radLat = degLat.degToRad();
        radLon = degLon.degToRad();
        // minimum and maximum latitudes for given distance
        minLat = radLat - radDist;
        maxLat = radLat + radDist;
        // minimum and maximum longitudes for given distance
        minLon = void 0;
        maxLon = void 0;
        // define deltaLon to help determine min and max longitudes
        deltaLon = Math.asin(Math.sin(radDist) / Math.cos(radLat));
        if (minLat > MIN_LAT && maxLat < MAX_LAT) {
            minLon = radLon - deltaLon;
            maxLon = radLon + deltaLon;
            if (minLon < MIN_LON) {
                minLon = minLon + 2 * Math.PI;
            }
            if (maxLon > MAX_LON) {
                maxLon = maxLon - 2 * Math.PI;
            }
        }
        // a pole is within the given distance
        else {
            minLat = Math.max(minLat, MIN_LAT);
            maxLat = Math.min(maxLat, MAX_LAT);
            minLon = MIN_LON;
            maxLon = MAX_LON;
        }
        return [
            minLon.radToDeg(),
            minLat.radToDeg(),
            maxLon.radToDeg(),
            maxLat.radToDeg()
        ];
    };
}