import {MnObject, Region} from "backbone.marionette";
import FilterCollection from "../../collection/FilterCollection";

const BaseFilterController = MnObject.extend({

    region: null,
    collection: null,

    initialize: function () {
        this.collection = new FilterCollection([], {
            url: this.getOption('paths').filter
        });
    },

    start: function () {
        this.region = new Region({
            'el': '#app_filter'
        });

        this.initModels();
        this.initViews();
    },

    initModels: function () {
        return Promise.all([this.collection.load({
            data: this.getOption('data').filter
        })]);
    },

    initViews: function () {
        throw 'Implement initViews';
    }

});

export default BaseFilterController;