import {Model} from "backbone";
import _ from "underscore";

const BaseModel = Model.extend({

    loadPromise: null,

    initialize: function (attrs, options) {
        if (options.url) {
            this.urlRoot = options.url;
        }
    },

    load: function (options, force = false) {
        if (!this.loadPromise || force) {
            this.loadPromise = new Promise((resolve, reject) => {
                if (!options) {
                    options = {};
                }

                options.success = resolve;
                options.error = reject;

                this.fetch(options);
            });
        }
        return this.loadPromise;
    },

    saveWithPromise: function () {
        return new Promise((resolve, reject) => {
            this.validationError = undefined;

            this.save(null, {
                success: resolve,
                error: (model, xhr) => {
                    let errors = {};
                    _.each(xhr.responseJSON.errors, (el) => {
                        errors[el.path] = el.error;
                    });
                    this.validationError = errors;

                    this.trigger('invalid');
                }
            });
        })
    },

    deleteWithPromise: function () {
        return new Promise((resolve, reject) => {
            this.validationError = undefined;

            this.destroy(null, {
                success: resolve,
                error: (model, xhr) => {
                    let errors = {};
                    _.each(xhr.responseJSON.errors, (el) => {
                        errors[el.path] = el.error;
                    });
                    this.validationError = errors;

                    this.trigger('invalid');
                }
            });
        })
    }

});

export default BaseModel;