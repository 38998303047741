import {MnObject, Region} from "backbone.marionette";
import _ from "underscore";
import MarkerCollection from "../../filter.module/collection/MarkerCollection";
import CompanyCollection from "../../filter.module/collection/CompanyCollection";

import TopCompanyMapCompanyListModuleView from "../view/TopCompanyMapCompanyListModuleView";
import TopCompanyMapModuleView from "../view/TopCompanyMapModuleView";

const TopCompanyMapController = MnObject.extend({

    mapRegion: null,
    companyListRegion: null,
    markerCollection: null,
    companyCollection: null,

    mapView: null,
    companyListView: null,

    filter: null,
    bounds: null,
    zoom: null,

    initialize: function () {
        this.markerCollection = new MarkerCollection([], {
            url: this.getOption('paths').marker
        });
        this.companyCollection = new CompanyCollection([], {
            url: this.getOption('paths').company
        });
    },

    start: function () {
        this.mapRegion = new Region({
            'el': '#app_top_company_map_container'
        });
        this.companyListRegion = new Region({
            'el': '#app_top_company_map_list_company_container'
        });

        this.initModels();
        this.initViews();
    },

    initModels: function () {
    },

    initViews: function () {
        // map view
        this.mapView = new TopCompanyMapModuleView({
            collection: this.markerCollection,
            center: this.getOption('center'),
            zoom: this.getOption('zoom')
        });

        this.listenTo(this.mapView, 'bounds:changed', this.onBoundsChanged);
        this.listenTo(this.mapView, 'map:clicked', this.onMapClicked);

        this.mapRegion.show(this.mapView);

        // company list
        this.companyListView = new TopCompanyMapCompanyListModuleView({
            collection: this.companyCollection,
            companyPageUrl: this.getOption('paths').company_page_base,
            companyBookUrl: this.getOption('paths').company_book_base,
            mapUrl: this.getOption('paths').map,
            locationName: this.getOption('locationName'),
            categoryName: this.getOption('categoryName'),
        });

        this.companyListRegion.show(this.companyListView);
    },

    onBoundsChanged: function (bounds, zoom) {
        this.bounds = bounds;
        this.zoom = zoom;

        this.updateMarkers();
        this.updateList();
    },

    onMapClicked: function () {
        window.location = this.getOption('paths').map;
    },

    setFilter: function (filter) {
        this.filter = filter;

        this.updateMarkers();
        this.updateList();
    },

    updateMarkers: function () {
        if (this.filter && this.bounds && this.zoom) {
            this.markerCollection.load({
                data: this.getFilterData()
            }, true);
        }
    },

    updateList: function () {
        if (this.filter && this.bounds && this.zoom) {
            this.companyCollection.getPaginator().setRequestData(
                this.getFilterData()
            );
        }
    },

    getFilterData: function () {
        return _.extend({}, this.filter, {
            zoom: this.zoom,
            bounds: this.bounds ? this.bounds.join(',') : null
        });
    }
});

export default TopCompanyMapController;