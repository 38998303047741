import {View} from "backbone.marionette";
import _template from "../../../app/library/_template";
import CompanyCollectionView from "./CompanyCollectionView";
import CompanyNextPageButtonView from "./CompanyNextPageButtonView";

const CompanyListModuleView = View.extend({

    template: _template('#app_company_list_module_template'),

    regions: {
        companyList: '.app-company-list-container',
        companyInfo: '.app-company-info-container',
        nextPageButton: '.app-next-button-container'
    },
    childViewEvents: {
        'next:page': 'onChildNextPage',
        'location:hover': 'onHighlightMarker',
        'location:leave': 'onUnHighlightMarker',
        'location:show': 'onDisplayMarker'
    },

    onDisplayMarker(model) {
        this.triggerMethod('location:show', model);
    },

    onHighlightMarker(model) {
        this.triggerMethod('location:hover', model);
    },

    onUnHighlightMarker(model) {
        this.triggerMethod('location:leave', model);
    },

    onChildNextPage() {
        this.triggerMethod('next:page');
    },

    companyListView: null,

    getListView() {
        if (!this.companyListView || this.companyListView.isDestroyed()) {
            this.companyListView = new CompanyCollectionView({
                collection: this.collection,
                companyPageUrl: this.getOption('companyPageUrl'),
                companyBookUrl: this.getOption('companyBookUrl'),
                categoryUrl: this.getOption('categoryUrl')
            });
        }
        return this.companyListView;
    },

    onRender() {
        this.showChildView('companyList', this.getListView());
        this.showChildView('nextPageButton', new CompanyNextPageButtonView({
            collection: this.collection
        }));
    }

});

export default CompanyListModuleView;