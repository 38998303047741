import "devbridge-autocomplete";

export default class SearchIndexPage {

    config = {
        template: '{{city}}, {{country}}',
        autocomplete_element: '',
        country_element: '',
        city_element: '',
        hours_slider_element: '',
        autocomplete_url: '',
        current_city: null
    };

    hoursSlider = null;

    /**
     * Constructor
     *
     * @param config
     */
    constructor(config = {}) {
        this.config = $.extend({}, this.config, config);

        this.initUi();
    }

    initUi() {
        $(this.config.autocomplete_element).autocomplete({
            serviceUrl: this.config.autocomplete_url,
            dataType: 'json',
            transformResult: this.transformResult.bind(this),
            onSelect: this.onSelect.bind(this),
            onSearchStart: this.onSearchStart.bind(this)
        });

        if (this.config.current_city) {
            $(this.config.autocomplete_element).val(this.getLabel(this.config.current_city));
        }
        $(this.config.autocomplete_element).focusin(function () {
            $(this).data('autocomplete').ignoreValueChange = true;
            $(this).select();
        });
    }

    onSelect(data) {
        $(this.config.country_element).val(data.data.country.id);
        $(this.config.city_element).val(data.data.id);
    }

    onSearchStart() {
        $(this.config.country_element).val('');
        $(this.config.city_element).val('');
    }

    transformResult(response) {
        const _this = this;
        return {
            suggestions: $.map(response.data, function(item) {
                return {
                    value: _this.getLabel(item),
                    data: item
                };
            })
        };
    }

    getLabel(data) {
        return this.config.template.replace('{{city}}', data.name).replace('{{country}}', data.country.name);
    }

}