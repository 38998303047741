import _ from "underscore";
import CurrencyFormatter from "../../app/library/marionette/lib/CurrencyFormatter";

export default class PriceFilterUi {

    inputFrom = null;
    inputTo = null;
    slider = null;
    label = null;
    clearBtn = null;
    currency = null;

    constructor(inputFrom, inputTo, slider, label, clearBtn, currency) {
        this.inputFrom = inputFrom;
        this.inputTo = inputTo;
        this.slider = slider;
        this.label = label;
        this.clearBtn = clearBtn;
        this.currency = currency;

        this.slider.ionRangeSlider({
            type: 'double',
            min: this.inputFrom.data('min'),
            max: this.inputTo.data('max') + 1,
            skin: 'round',
            hide_min_max: true,
            onChange: (e) => this.sliderHandler(e)
        });

        this.initEvents();
        this.inputHandler();
    }

    sliderHandler(event) {
        this.inputFrom.val(event.from);
        this.inputTo.val(event.to);

        this.handleInputNames();
        this.updateTitle();
    }

    inputHandler() {
        if (!this.inputFrom.val()) {
            this.inputFrom.val(this.inputFrom.data('min'));
        }

        if (!this.inputTo.val()) {
            this.inputTo.val(this.inputTo.data('max') + 1);
        }

        this.slider.data("ionRangeSlider").update({
            from: this.inputFrom.val(),
            to: this.inputTo.val()
        });
        this.handleInputNames();
        this.updateTitle();
    }

    handleInputNames() {
        if (this.inputTo.val() == (this.inputTo.data('max') + 1) && this.inputFrom.val() == this.inputFrom.data('min')) {
            this.inputTo.attr('name', '').val(null);
            this.inputFrom.attr('name', '').val(null);
        } else {
            this.inputTo.attr('name', 'price[to]');
            this.inputFrom.attr('name', 'price[from]');
        }
    }

    initEvents() {
        this.inputFrom.change(() => {
            if (parseInt(this.inputFrom.val()) > parseInt(this.inputTo.val())) {
                this.inputTo.val(this.inputFrom.val());
            }
            this.inputHandler();
        });
        this.inputTo.change(() => {
            if (parseInt(this.inputTo.val()) < parseInt(this.inputFrom.val())) {
                this.inputFrom.val(this.inputTo.val());
            }
            this.inputHandler()
        });
        this.clearBtn.click(() => {
            this.inputFrom.val(this.inputTo.data('min'));
            this.inputTo.val(this.inputTo.data('max') + 1);
            this.inputHandler();

            return false;
        });
        $(document).click((event) => {
            if ($(event.target).closest('.collapse').length === 0) {
                this.clearBtn.closest('.collapse').collapse('hide');
            }
        });
    }

    updateTitle() {
        if (!this.inputFrom.val() && !this.inputTo.val()) {
            this.label.removeClass('active').text('Any price');
        } else {
            this.label.addClass('active').text(
                this.formatPrice(this.inputFrom.val()) + ' - ' + this.formatPrice(this.inputTo.val())
            );
        }
    }

    formatPrice(price) {
        return CurrencyFormatter.getInstance().format(price, this.currency);
    }

}