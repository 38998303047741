import _template from "../../../app/library/_template";
import Factory from "../../../app/library/map/Factory";
import MapListener from "../../../app/library/map/listener/MapListener";
import _ from "underscore";
import {View} from "backbone.marionette";

const TopCompanyMapModuleView = View.extend({
    template: _template('#app_top_company_map_map_module_template'),

    ui: {
        'map': '.app-map'
    },
    collectionEvents: {
        'sync': 'updateMarkers'
    },

    mapListener: null,

    initialize: function () {
        this.mapListener = new MapListener(
            _.bind(this.onBoundsChanged, this),
            _.bind(this.onMapClicked, this),
            _.bind(this.onMapClicked, this)
        );
    },

    updateMarkers() {
        this.map.clearMarkers();

        this.collection.each((marker) => {
            if (marker.get('type') === 'marker') {
                this.map.addMarker(marker.get('point').lat, marker.get('point').lng, marker.get('value'), marker.get('data'));
            } else {
                this.map.addCluster(marker.get('point').lat, marker.get('point').lng, marker.get('count'), marker.get('value'));
            }
        });
    },

    onDomRefresh: function () {
        this.initMap();
    },

    initMap: function () {
        this.map = Factory.getMap({
            listener: this.mapListener
        });

        let params = {
            center: this.getOption('center'),
            inactive: true
        };

        if (this.getOption('zoom')) {
            params.zoom = this.getOption('zoom');
        }

        this.map.attachMap(this.ui.map, params);
    },

    onBoundsChanged: function (bounds, zoom) {
        this.trigger('bounds:changed', bounds, zoom);
    },

    onMapClicked: function () {
        this.trigger('map:clicked');
    }
});

export default TopCompanyMapModuleView;