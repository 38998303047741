import {MnObject, Region} from "backbone.marionette";
import CompanyReviewsView from "../view/CompanyReviewsView";
import ReviewsCollection from "../collection/ReviewsCollection";
import CompanyReviewFormView from "../view/CompanyReviewFormView";
import ReviewModel from "../model/ReviewModel";

const ReviewsController = MnObject.extend({

    reviewsRegion: null,
    formRegion: null,
    collection: null,
    profile: null,

    initialize: function () {
        this.collection = new ReviewsCollection(this.getOption('reviews'), {
            url: this.getOption('paths').reviews_end_point
        });
        this.collection.updatePagesCount();
        this.profile = this.getOption('profile');

        this.reviewsRegion = new Region({
            'el': '#app_reviews_list_container'
        });
        this.formRegion = new Region({
            'el': '#app_reviews_form_container'
        });
    },

    start: function () {
        let view = new CompanyReviewsView({
            collection: this.collection,
            profile: this.profile
        });
        this.listenTo(view, 'delete:review', this.deleteReview);

        this.reviewsRegion.show(view);
        this.showForm();
    },

    showForm: function () {
        let view = new CompanyReviewFormView({
            collection: this.collection,
            profile: this.profile,
            paths: this.getOption('paths'),
            captchaSiteKey: this.getOption('captchaSiteKey'),
            model: new ReviewModel(null, {
                url: this.getOption('paths').review_post_end_point
            })
        });
        this.listenTo(view, 'save:review', this.saveReview);
        this.listenTo(view, 'profile:logout', this.logoutProfile);

        this.formRegion.show(view);
    },

    logoutProfile: function () {
        $.post(this.getOption('paths').logout, () => {
            this.profile = null;
            this.start();
        });
    },

    deleteReview: function (model) {
        model.urlRoot = this.getOption('paths').review_delete_end_point;
        model.deleteWithPromise().then(() => {
            this.collection.remove(model);
        });
    },

    saveReview: function (model) {
        model.saveWithPromise().then(() => {
            this.collection.unshift(model);
            this.showForm();
        });
    },

    show: function (page) {
        if (page) {
            this.collection.getPaginator().setPage(page);
        }
    }

});

export default ReviewsController;