export default class DateFilterUi {

    picker = null;
    input = null;
    label = null;
    clearBtn = null;

    constructor(picker, input, label, clearBtn) {
        this.picker = picker;
        this.input = input;
        this.label = label;
        this.clearBtn = clearBtn;

        if (this.input.val()) {
            this.picker.datepicker('update', new Date(this.input.val()));
            this.label.text(this.picker.datepicker('getFormattedDate'));
        }

        this.initEvents();
    }

    initEvents() {
        this.picker.on('changeDate', () => {
            this.input.val(this.picker.datepicker('getFormattedDate', 'yyyy-mm-dd'));
            this.label.addClass('active').text(this.picker.datepicker('getFormattedDate'));
        });
        this.clearBtn.click(() => {
            this.picker.datepicker('update', null);
            this.input.val('');
            // @todo translate
            this.label.removeClass('active').text('Any date');

            return false;
        });
        this.label.click(() => {
            this.picker.datepicker({todayHighlight: true});
        });
        $(document).click(() => {
            this.picker.closest('.collapse').collapse('hide');
        });
    }

}