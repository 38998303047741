import _template from "../app/library/_template";
import _ from "underscore";

export default class LocationSelectUi {

    template = _template('#app_ui_location_select');

    el = null;
    container = null;
    label = null;
    cityInput = null;
    countryInput = null;
    latInput = null;
    lngInput = null;
    confirmLocationBtn = null;
    wrongLocationBtn = null;
    confirmLocationDialog = null;

    config = {};
    location = {};

    countryCollection = null;
    cityCollection = null;
    stateCollection = null;

    loadedCountryId = null;
    loadedStateId = null;

    searchText = '';

    animationDuration = 200;

    constructor(el, config) {
        this.el = $(el);
        this.config = config;

        this.location = config.location || {};

        this.initDom();
        this.initEvents();
        this.detectLocation();
    }

    initDom() {
        this.label = this.el.find('.app-location-label');
        this.cityInput = this.el.find('.app-city-input');
        this.countryInput = this.el.find('.app-country-input');

        this.latInput = this.el.find('.app-lat-input');
        this.lngInput = this.el.find('.app-lng-input');

        this.container = this.el.find('.app-search-container');

        this.confirmLocationBtn = this.el.find('.app-confirm-location');
        this.wrongLocationBtn = this.el.find('.app-change-location');
        this.confirmLocationDialog = this.el.find('.app-confirm-location-dialog');

        this.render();
    }

    initEvents() {
        const $pageOverlay = $('#dr_overlay');

        this.container.on('shown.bs.collapse', () => {
            this.el.addClass('opened');
            $pageOverlay.addClass('show');
        });
        this.container.on('hidden.bs.collapse', () => {
            this.el.removeClass('opened');
            $pageOverlay.removeClass('show');
        });
        this.wrongLocationBtn.click(() => {
            this.confirmLocationDialog.slideUp(this.animationDuration, () => {
                this.label.click();
            });
        });
        this.confirmLocationBtn.click(() => {
            this.confirmLocationDialog.slideUp(this.animationDuration);
            $.getJSON(this.config.confirm_location_end_point);
        });

        this.label.click(() => {
            this.confirmLocationDialog.slideUp(this.animationDuration);

            this.loadCountries().then(() => {
                let promises = [];
                if (this.location.country_id) {
                    let selectedCountry = _.findWhere(this.countryCollection, {id: this.location.country_id});

                    if (selectedCountry.with_states) {
                        promises.push(this.loadStates());
                    } else {
                        promises.push(this.loadCities());
                    }
                    if (this.location.city_id) {
                        promises.push(this.loadCities());
                    }
                }

                Promise.all(promises).then(_.bind(this.render, this));
            });
        });

        $(document).click((event) => {
            if ($(event.target).closest(this.container).length === 0) {
                this.container.collapse('hide');
            }
        });
    }

    detectLocation() {
        if (!this.location.selected_by_client && this.config.main_selector && ("geolocation" in navigator)) {
            navigator.geolocation.getCurrentPosition((position) => {
                let coords = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                $.post(this.config.update_location_end_point, coords, (data) => {
                    this.location = data.location;
                    this.label.text(data.location_name);
                });
            });
        }
    }

    loadCountries() {
        return new Promise((resolve) => {
            if (!this.countryCollection) {
                $.getJSON(this.config.countries_end_point, {
                    skipLocationWithoutCompanies: this.config.skip_location_without_companies
                }, (data) => {
                    this.countryCollection = data.data;

                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    loadCities() {
        return new Promise((resolve) => {
            if (this.cityCollection === null || this.loadedCountryId !== this.location.country_id || this.loadedStateId !== this.location.location_id) {
                $.getJSON(this.config.cities_end_point, {
                    skipLocationWithoutCompanies: this.config.skip_location_without_companies,
                    filter: {country: this.location.country_id, state: this.location.state_id}
                }, (data) => {
                    this.cityCollection = data.data;
                    this.loadedCountryId = this.location.country_id;
                    this.loadedStateId = this.location.state_id;

                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    loadStates() {
        return new Promise((resolve) => {
            if (!this.stateCollection || this.loadedCountryId !== this.location.country_id) {
                $.getJSON(this.config.states_end_point, {
                    skipLocationWithoutCompanies: this.config.skip_location_without_companies,
                    filter: {country: this.location.country_id}
                }, (data) => {
                    this.stateCollection = data.data;
                    this.loadedCountryId = this.location.country_id;

                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    render() {
        this.searchText = '';

        let mode = 'loading';

        let selectedCountry = _.findWhere(this.countryCollection, {id: this.location.country_id});
        let selectedState = _.findWhere(this.stateCollection, {id: this.location.state_id});
        let selectedCity = _.findWhere(this.cityCollection, {id: this.location.city_id});

        let containerClass = '';
        if (this.countryCollection !== null && !this.location.country_id) {
            mode = 'countries';
            containerClass = 'country';
        }
        if (selectedCountry) {
            if (selectedCountry.with_states && this.stateCollection !== null && this.location.country_id) {
                mode = 'states';
                containerClass = 'country state';
            }
            if (this.cityCollection !== null && this.location.country_id && (this.location.state_id || !selectedCountry.with_states)) {
                mode = 'cities';
                containerClass = 'country state city';
            }
        }

        this.container.removeClass('country state city');
        this.container.addClass(containerClass);

        this.container.html(this.template({
            mode: mode,
            countries: this.countryCollection,
            states: this.stateCollection,
            cities: this.cityCollection,
            location: this.location,
            selectedCity: selectedCity,
            selectedState: selectedState,
            selectedCountry: selectedCountry,
            searchText: this.searchText
        }));
        this.initRenderedItemsEvents();
    }

    initRenderedItemsEvents() {
        $('.app-item-submit', this.container).click((event) => {
            let data = $(event.target).data();

            $('.app-city-input', this.el).val(data.city || '');
            $('.app-state-input', this.el).val(data.state || '');
            $('.app-country-input', this.el).val(data.country || '');

            $('form', this.el).submit();

            return false;
        });
        $('.app-country-item', this.container).click((event) => {
            this.location.country_id = $(event.target).data('id');
            this.render();

            let selectedCountry = _.findWhere(this.countryCollection, {id: this.location.country_id});
            if (selectedCountry.with_states) {
                this.loadStates().then(_.bind(this.render, this));
            } else {
                this.loadCities().then(_.bind(this.render, this));
            }

            return false;
        });
        $('.app-state-item', this.container).click((event) => {
            this.location.state_id = $(event.target).data('id');
            this.render();
            this.loadCities().then(_.bind(this.render, this));

            return false;
        });
        $('.app-change-country', this.container).click(() => {
            this.location.country_id = null;
            this.location.state_id = null;
            this.location.city_id = null;

            this.stateCollection = null;
            this.cityCollection = null;

            this.render();

            return false;
        });
        $('.app-change-state', this.container).click(() => {
            this.location.state_id = null;
            this.location.city_id = null;

            this.cityCollection = null;

            this.render();

            return false;
        });
        $('.app-search-box', this.container).keyup((event) => {
            this.searchText = $(event.target).val().toLowerCase();
            this.filterItems();
        }).focus();
    }

    filterItems() {
        $('.app-item', this.container).each((index, el) => {
            if ($(el).text().toLowerCase().search(this.searchText) < 0) {
                $(el).hide();
            } else {
                $(el).show();
            }
        });
    }

}