import _template from "../../../app/library/_template";
import TopCompanyMapCompanyCollectionView from "./TopCompanyMapCompanyCollectionView";
import {View} from "backbone.marionette";
import _ from "underscore";

const TopCompanyMapCompanyListModuleView = View.extend({
    template: _template('#app_top_company_map_company_list_module_template'),

    regions: {
        companyList: '.app-top-company-map-company-list-container'
    },

    collectionEvents: {
        sync: 'syncRender'
    },

    companyListView: null,

    getListView() {
        if (!this.companyListView || this.companyListView.isDestroyed()) {
            this.companyListView = new TopCompanyMapCompanyCollectionView({
                collection: this.collection,
                companyPageUrl: this.getOption('companyPageUrl'),
                companyBookUrl: this.getOption('companyBookUrl'),
                imgDomain: this.getOption('imgDomain')
            });
        }
        return this.companyListView;
    },

    render() {
        //TODO fake render for once render child view
    },

    syncRender() {
        View.prototype.render.call(this);
        this.showChildView('companyList', this.getListView());
    },

    onDomRefresh() {
        new CompanyShowOnMap();
    },

    serializeData() {
        return _.extend(View.prototype.serializeData.apply(this, arguments), {
            total: this.collection.getTotal(),
            mapUrl: this.getOption('mapUrl'),
            locationName: this.getOption('locationName'),
            categoryName: this.getOption('categoryName'),
        });
    }
});

export default TopCompanyMapCompanyListModuleView;