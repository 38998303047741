import $ from "jquery";

export default class MapListener {

    onBoundsChanged = $.noop;
    onMarkerClicked = $.noop;
    onMapClicked = $.noop;

    constructor(onBoundsChanged, onMarkerClicked, onMapClicked) {
        if (onBoundsChanged) {
            this.onBoundsChanged = onBoundsChanged;
        }
        if (onMarkerClicked) {
            this.onMarkerClicked = onMarkerClicked;
        }
        if (onMapClicked) {
            this.onMapClicked = onMapClicked;
        }
    }

}