import {Model} from "backbone";

const FilterItemModel = Model.extend({

    defaults: {

    },

    toJSON: function () {
        let result = Model.prototype.toJSON.apply(this, arguments);

        result.is_attribute = !!result.attribute_type;
        result.field_name = result.key;
        if (result.is_attribute) {
            result.field_name = 'attr[' + result.key + ']';
        }

        return result;
    }

});

export default FilterItemModel;