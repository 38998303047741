import MapFilterModuleView from "../view/MapFilterModuleView";
import BaseFilterController from "./base/BaseFilterController";
import _ from "underscore";

const MapFilterController = BaseFilterController.extend({

    initViews: function () {
        let view = new MapFilterModuleView({
            collection: this.collection,
            client_location: this.getOption('data').client_location
        });

        this.listenTo(view, 'form:submit', this.onFormSubmit);

        this.region.show(view);
    },

    onFormSubmit: function (data) {
        let baseData = {};
        if (this.getOption('data').filter.category) {
            baseData.category = this.getOption('data').filter.category;
        }
        if (this.getOption('data').filter.city) {
            baseData.city = this.getOption('data').filter.city;
            baseData.country = this.getOption('data').filter.country;
        }
        if (this.getOption('data').filter.tag) {
            baseData.tag = this.getOption('data').filter.tag;
            baseData.widget = this.getOption('data').filter.widget;
        }
        let params = _.extend(baseData, data);

        this.updateFilter(params);
    },

    updateFilter: function (params) {
        this.collection.load({
            data: params
        }, true);

        this.triggerMethod('filter:changed', params);
    }

});

export default MapFilterController;