import _template from "../../../app/library/_template";
import CompanyLocationItemView from "./CompanyLocationItemView";

const CompanyLocationSingleItemView = CompanyLocationItemView.extend({

    template: _template('#app_company_location_single_item_view'),

    className: ''
});

export default CompanyLocationSingleItemView;