import {View} from "backbone.marionette";
import CompanyLocationsCollectionView from "./CompanyLocationCollectionView";
import CompanyLocationCollection from "../collection/CompanyLocationCollection";
import _ from "underscore";
import _template from "../../../app/library/_template";
import '../../../dom.ui/company/partial/CompanyServiceListPartial'
import CompanyLocationSingleItemView from "./CompanyLocationSingleItemView";
import CompanyLocationModel from "../model/CompanyLocationModel";

const CompanyItemView = View.extend({

    template: _template('#app_company_item_view'),

    regions: {
        'locations': '.app_locations_container'
    },

    ui: {
        'showMore': '.show-more-companies-on-map-button',
    },

    childViewEvents: {
        'location:hover': 'triggerLocationHover',
        'location:leave': 'triggerLocationLeave',
        'location:show': 'triggerLocationShow'
    },

    events: {
        'click @ui.showMore': 'onShowMoreClicked'
    },

    triggerLocationShow(model) {
        this.triggerMethod('location:show', model);
    },

    triggerLocationHover(model) {
        this.triggerMethod('location:hover', model);
    },

    triggerLocationLeave(model) {
        this.triggerMethod('location:leave', model);
    },

    onShowMoreClicked() {
        this.trigger('showMore:clicked');
    },

    onRender() {
        this.showChildView('locations', this.getLocationChildView());
    },

    serializeData() {
        let selectedLocation = null;
        let locationId = this.getOption('locationId');
        if (locationId) {
            let locations = this.model.get('locations');
            selectedLocation = locations.find(location => location.id === locationId)
        }

        return _.extend(View.prototype.serializeData.apply(this, arguments), {
            base_company_page_url: this.getOption('companyPageUrl'),
            base_company_book_url: this.getOption('companyBookUrl'),
            base_category_url: this.getOption('categoryUrl'),
            with_show_more_button: this.getOption('withShowMoreButton'),
            external_location_id: selectedLocation ? selectedLocation.external_id : null
        });
    },

    getLocationChildView() {
        let locations = this.model.get('locations');

        return locations.length > 1
            ? new CompanyLocationsCollectionView({
                collection: new CompanyLocationCollection(locations),
                companyPageUrl: this.getOption('companyPageUrl'),
                companyBookUrl: this.getOption('companyBookUrl'),
                locationId: this.getOption('locationId')
            })
            : new CompanyLocationSingleItemView({
                model: new CompanyLocationModel(locations[0]),
                companyPageUrl: this.getOption('companyPageUrl'),
                companyBookUrl: this.getOption('companyBookUrl'),
            });
    }

});

export default CompanyItemView;