import {Collection} from "backbone";

const BaseCollection = Collection.extend({

    loadPromise: null,

    initialize: function (items, options) {
        if (options.url) {
            this.url = options.url;
        }
    },

    load: function (options, force = false) {
        if (!this.loadPromise || force) {
            this.loadPromise = new Promise((resolve, reject) => {
                if (!options) {
                    options = {};
                }

                options.success = resolve;
                options.error = reject;

                this.fetch(options);
            });
        }
        return this.loadPromise;
    }
    
});

export default BaseCollection;