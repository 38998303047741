import BaseCollection from "../../../app/library/marionette/collection/BaseCollection";
import Paginator from "../../../app/library/marionette/collection/Paginator";
import ReviewModel from "../model/ReviewModel";

const ReviewsCollection = BaseCollection.extend({

    model: ReviewModel,
    paginator: null,

    initialize: function (items, options) {
        this.url = options.url;
        this.paginator = new Paginator();
    },

    updatePagesCount: function () {
        this.paginator.setPagesCount(this._calcPagesCount(this.length));
    },

    getPaginator: function () {
        return this.paginator;
    },

    getPageReviews: function () {
        return this.toJSON().slice(
            (this.getPaginator().getPage() - 1) * this.getPaginator().getOnPage(),
            this.getPaginator().getPage() * this.getPaginator().getOnPage()
        );
    },

    _calcPagesCount: function (count) {
        return Math.ceil(count / this.getPaginator().getOnPage());
    }

});

export default ReviewsCollection;