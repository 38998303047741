import {MnObject, Region} from "backbone.marionette";
import _ from "underscore";
import MarkerCollection from "../collection/MarkerCollection";
import MapModuleView from "../view/MapModuleView";
import CompanyCollection from "../collection/CompanyCollection";
import CompanyListModuleView from "../view/CompanyListModuleView";
import CompanyModel from "../model/CompanyModel";
import CompanyItemView from "../view/CompanyItemView";
import $ from "jquery";

const MapController = MnObject.extend({

    mapRegion: null,
    companyListRegion: null,
    markerCollection: null,
    companyCollection: null,

    mapView: null,
    companyListView: null,

    filter: null,
    bounds: null,
    zoom: null,

    initialize: function () {
        this.markerCollection = new MarkerCollection([], {
            url: this.getOption('paths').marker
        });
        this.companyCollection = new CompanyCollection([], {
            url: this.getOption('paths').company
        });
    },

    start: function () {
        this.mapRegion = new Region({
            'el': '#app_map_container'
        });
        this.companyListRegion = new Region({
            'el': '#app_list_container'
        });
        this.companyInfoRegion = new Region({
            'el': '#app_info_container'
        });
        this.companyInfoRegion.$el.hide();

        this.initModels();
        this.initViews();
    },

    initModels: function () {
    },

    initViews: function () {
        this.zoom = this.getOption('data').filter.zoom;

        // map view
        this.mapView = new MapModuleView({
            collection: this.markerCollection,
            center: this.getOption('center'),
            bound: this.getOption('bound'),
            zoom: this.zoom
        });
        this.listenTo(this.mapView, 'bounds:changed', this.onBoundsChanged);
        this.listenTo(this.mapView, 'marker:clicked', this.onMarkerClicked);
        this.listenTo(this.mapView, 'map:clicked', this.onMapClicked);

        this.mapRegion.show(this.mapView);

        // company list
        this.companyListView = new CompanyListModuleView({
            collection: this.companyCollection,
            companyPageUrl: this.getOption('paths').company_page_base,
            companyBookUrl: this.getOption('paths').company_book_base,
            categoryUrl: this.getOption('paths').category_base
        });

        this.listenTo(this.companyListView, 'next:page', this.onNextPage);
        this.listenTo(this.companyListView, 'location:hover', this.onLocationHover);
        this.listenTo(this.companyListView, 'location:leave', this.onLocationLeave);
        this.listenTo(this.companyListView, 'location:show', this.onLocationDisplay);

        this.companyListRegion.show(this.companyListView);
    },

    onNextPage: function () {
        this.companyCollection.getPaginator().nextPage();
    },

    onLocationHover: function (model) {
        this.mapView.highlightMarker(model.get('id'));
    },

    onLocationDisplay: function (model) {
        this.onMarkerClicked(model.get('company_login'), model.get('id'), true, model.get('point'));
    },

    onLocationLeave: function (model) {
        this.mapView.unHighlightMarker(model.get('id'));
    },

    onBoundsChanged: function (bounds, zoom) {
        this.bounds = bounds;
        this.zoom = zoom;

        this.updateMarkers();
        this.updateList();
        this.updateUrl();
    },

    onMarkerClicked: function (login, locationId, flyTo, point) {
        let lastZoom = this.zoom;
        this.mapView.unHighlightMarkers();
        this.mapView.highlightMarker(locationId);
        if (flyTo) {
            this.mapView.flyTo(point.lng, point.lat);
        }

        let filterData = {
            page: 1,
            maxResults: this.getOption('data').max_results_company_details,
            locationId: locationId,
            companyLogin: login
        };

        let model = new CompanyModel({}, {
            url: this.getOption('paths').company_details
        });

        model.load({data: filterData}).then(() => {
            let view = new CompanyItemView({
                model: model,
                locationId: locationId,
                companyPageUrl: this.getOption('paths').company_page_base,
                companyBookUrl: this.getOption('paths').company_book_base,
                withShowMoreButton: true
            });
            this.companyInfoRegion.show(view);

            this.listenTo(view, 'location:show', this.onLocationDisplay);
            this.listenTo(view, 'showMore:clicked', function () {
                this.mapView.zoomTo(lastZoom);
                this.onMapClicked();
            });
        });

        this.companyListRegion.$el.hide();
        this.companyInfoRegion.$el.show();
    },

    onMapClicked: function () {
        this.mapView.unHighlightMarkers();

        this.companyListRegion.$el.show();
        this.companyInfoRegion.$el.hide();
    },

    setFilter: function (filter) {
        this.filter = filter;

        this.updateMarkers();
        this.updateList();
        this.updateUrl();
    },

    updateMarkers: function () {
        if (this.filter && this.bounds && this.zoom) {
            this.markerCollection.load({
                data: this.getFilterData()
            }, true);
        }
    },

    updateList: function () {
        if (this.filter && this.bounds && this.zoom) {
            this.companyCollection.getPaginator().setRequestData(
                this.getFilterData()
            );
        }
    },

    getFilterData: function () {
        return _.extend({}, this.filter, {
            zoom: this.zoom,
            bounds: this.bounds ? this.bounds.join(',') : null
        });
    },

    updateUrl: function () {
        if (this.filter && this.bounds && this.zoom && _.isFunction(history.replaceState)) {
            let data = this.getFilterData();
            delete data.category;

            $('.locale').each(function() {
                this.search = '?' + $.param(data);
                $(this).attr('href', this.href);
            });

            history.replaceState({}, '', location.pathname + '?' + $.param(data));
        }

        if (this.zoom) {
            let _this = this;
            $('.search-box-zoom').each(function() {
                $(this).val(_this.zoom);
            });
        }


        let listLink = $('.app-listview-link');
        let loc = listLink.get(0);
        let data = _.clone(this.filter);

        delete data.bounds;
        delete data.zoom;
        delete data.category;

        if (window.parent && window.parent !== window) {
            window.parent.postMessage({
                'event': 'updateUrlGetParams',
                'urlGetParams': '?' + $.param(this.getFilterData())
            }, '*');
        }

        loc.search = $.param(data);
        listLink.attr('href', loc.href);
    }
});

export default MapController;