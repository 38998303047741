import {CollectionView} from "backbone.marionette";
import CompanyItemView from "./CompanyItemView";
import CompanyEmptyView from "./CompanyEmptyView";

const CompanyCollectionView = CollectionView.extend({

    childView: CompanyItemView,
    emptyView: CompanyEmptyView,
    childViewOptions: function () {
        return {
            companyPageUrl: this.getOption('companyPageUrl'),
            companyBookUrl: this.getOption('companyBookUrl'),
            categoryUrl: this.getOption('categoryUrl')
        };
    },
    childViewEvents: {
        'location:hover': 'triggerLocationHover',
        'location:leave': 'triggerLocationLeave',
        'location:show': 'triggerLocationShow'
    },
    collectionEvents: {
        sync: 'render'
    },

    triggerLocationShow(model) {
        this.triggerMethod('location:show', model);
    },

    triggerLocationHover(model) {
        this.triggerMethod('location:hover', model);
    },

    triggerLocationLeave(model) {
        this.triggerMethod('location:leave', model);
    },

    initialize() {
        this.listenTo(this.collection.getPaginator(), 'before:change:filter', () => {
            this.$el.closest('.app-company-list').scrollTop(0);
        });
    }

});

export default CompanyCollectionView;