import {Model} from "backbone";

const MarkerModel = Model.extend({

    defaults: {

    }

});

export default MarkerModel;