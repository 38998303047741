import {View} from "backbone.marionette";
import _ from "underscore";
import _template from "../../../app/library/_template";

const CompanyLocationItemView = View.extend({

    template: _template('#app_company_location_item_view'),

    className: 'location__list-item',

    ui: {
        'showOnMap': '.app-show-on-map'
    },

    events: {
        'mouseenter': 'onHover',
        'mouseleave': 'onLeave',
        'click @ui.showOnMap': 'onShowOnMap'
    },

    onShowOnMap() {
        this.triggerMethod('location:show', this.model);

        return false;
    },

    onHover() {
        this.triggerMethod('location:hover', this.model);
    },

    onLeave() {
        this.triggerMethod('location:leave', this.model);
    },

    serializeData() {
        let url = this.getOption('companyBookUrl') + this.model.get('company_login');

        if (this.model.get('external_id')) {
            url += '?location=' + this.model.get('external_id');
        }
        return _.extend(View.prototype.serializeData.apply(this, arguments), {
            url: url
        });
    }

});

export default CompanyLocationItemView;