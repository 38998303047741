import {View} from "backbone.marionette";
import _template from "../../../app/library/_template";

const CompanyReviewFormView = View.extend({

    template: _template('#app_company_reviews_form'),

    ui: {
        'submit': '#app_review_submit',
        'subject': '.app-review-subject',
        'body': '.app-review-body',
        'rate': '.app-review-rate input',
        'logout': '#app_review_logout',
        'gRecaptcha': '#g-recaptcha',
    },
    events: {
        'click @ui.submit': 'submit',
        'click @ui.logout': 'logout'
    },
    modelEvents: {
        'invalid': 'render'
    },

    submit: function () {
        this.model.set('subject', this.ui.subject.val());
        this.model.set('body', this.ui.body.val());
        this.model.set('rate', parseInt(this.ui.rate.filter(':checked').val()));
        this.model.set('gRecaptchaResponse', $('.g-recaptcha-response', this.$el).val());

        this.ui.submit.prop('disabled', true);

        this.triggerMethod('save:review', this.model);
    },

    logout: function () {
        this.triggerMethod('profile:logout');
    },

    serializeData: function () {
        return {
            paths: this.getOption('paths'),
            profile: this.getOption('profile'),
            captchaSiteKey: this.getOption('captchaSiteKey'),
            review: this.model.toJSON(),
            errors: this.model.validationError
        };
    },

    onDomRefresh: function () {
        if (typeof grecaptcha !== "undefined" && this.ui.gRecaptcha.length) {
            grecaptcha.render('g-recaptcha', {
                'sitekey' : this.getOption('captchaSiteKey'),
                'theme' : 'light'
            })
        }
    }

});

export default CompanyReviewFormView;