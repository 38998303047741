import BaseModel from "../../../app/library/marionette/model/BaseModel";

const ReviewModel = BaseModel.extend({

    defaults: {
        rate: 5
    }

});

export default ReviewModel;