import BaseCollection from "./BaseCollection";
import CurrencyModel from "../model/CurrencyModel";

const CurrencyCollection = BaseCollection.extend({

    model: CurrencyModel,

    initialize: function (items, options) {
        this.url = options.url;
    },

    parse: function (response) {
        return response.data;
    }

});

export default CurrencyCollection;