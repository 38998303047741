import {CollectionView, View} from "backbone.marionette";
import CompanyLocationItemView from "./CompanyLocationItemView";
import _template from "../../../app/library/_template";
import _ from "underscore";

const CompanyLocationsCollectionView = CollectionView.extend({

    template: _template('#app_company_locations_item_view'),
    currentLocation: null,
    childViewContainer: '.location__list-wrapper',

    childView: CompanyLocationItemView,
    childViewOptions: function () {
        return {
            companyPageUrl: this.getOption('companyPageUrl'),
            companyBookUrl: this.getOption('companyBookUrl')
        }
    },

    // //TODO IS NEED?
    // events: {
    //     'mouseenter': 'onHoverCollectionView',
    //     'mouseleave': 'onLeaveCollectionView'
    // },
    //
    // onHoverCollectionView() {
    //     this.triggerLocationHover(this.currentLocation);
    // },
    //
    // onLeaveCollectionView() {
    //     this.triggerLocationLeave(this.currentLocation);
    // },

    childViewEvents: {
        'location:hover': 'triggerLocationHover',
        'location:leave': 'triggerLocationLeave',
        'location:show': 'triggerLocationShow',
    },

    triggerLocationShow(model) {
        this.triggerMethod('location:show', model);
    },

    triggerLocationHover(model) {
        this.triggerMethod('location:hover', model);
    },

    triggerLocationLeave(model) {
        this.triggerMethod('location:leave', model);
    },

    initialize() {
        let locationId = this.getOption('locationId');

        this.currentLocation = locationId
            ? this.getOption('collection')._byId[locationId]
            : this.getOption('collection').first();

        //TODO - remove after update indexing location without services
        if (!this.currentLocation) {
            this.currentLocation = this.getOption('collection').first();
        }
    },

    serializeData() {
        return _.extend(View.prototype.serializeData.apply(this, arguments), {
            address: this.currentLocation.get('address'),
            description: this.currentLocation.get('description'),
            companyLogin: this.currentLocation.get('company_login')
        });
    },
});

export default CompanyLocationsCollectionView;