import {View} from "backbone.marionette";
import _template from "../../../app/library/_template";
import Handlebars from "handlebars/dist/handlebars";

Handlebars.registerPartial('paginator', $('#app_paginator').html());

const CompanyReviewsView = View.extend({

    template: _template('#app_company_reviews_list'),

    ui: {
        'deleteReview': '.app_review_delete',
        'deleteReviewConfirm': '#app_review_delete_confirm',
        'deleteReviewCancel': '#app_review_delete_cancel',
        'deleteReviewConfirmDialog': '#app_review_delete_confirm_dialog',
    },
    events: {
        'click @ui.deleteReview': 'deleteReview',
        'click @ui.deleteReviewConfirm': 'deleteReviewConfirm',
        'click @ui.deleteReviewCancel': 'hideDeleteReviewConfirmDialog',
    },

    initialize: function () {
        this.listenTo(this.collection, 'sync', this.render);
        this.listenTo(this.collection, 'update', () => {
            this.collection.updatePagesCount();
            this.render();
        });
        this.listenTo(this.collection.getPaginator(), 'change', this.render);
    },

    deleteReview: function (event) {
        this.selectedReview = this.collection.get($(event.target).data('id'));
        this.ui.deleteReviewConfirmDialog.addClass("active");
    },

    deleteReviewConfirm: function () {
        this.triggerMethod('delete:review', this.selectedReview);
        this.hideDeleteReviewConfirmDialog();
    },

    hideDeleteReviewConfirmDialog: function () {
        this.selectedReview = null;
        this.ui.deleteReviewConfirmDialog.removeClass("active");
    },

    serializeData: function () {
        return {
            reviews: this.collection.getPageReviews(),
            pages: this.collection.getPaginator().getPagesData(),
            profile: this.getOption('profile')
        };
    }

});

export default CompanyReviewsView;