import CurrencyCollection from "../collection/CurrencyCollection";
import number_format from "number_format-php";

export default class CurrencyFormatter {

    static instance;

    currencyCollection = null;

    static getInstance() {
        if (!this.instance) {
            this.instance = new CurrencyFormatter();
        }
        return this.instance;
    }

    init(options) {
        if (!this.currencyCollection) {
            this.currencyCollection = new CurrencyCollection([], options);
        }
        return this.currencyCollection.load();
    }

    format(amount, currencyId) {
        let currency = this.currencyCollection.get(currencyId);
        let number = number_format(
            amount, currency.get('decimal_digits'), currency.get('decimal_separator'), currency.get('thousands_separator')
        );

        let res = [];
        if (currency.get('symbol_on_left')) {
            res.push(currency.get('symbol'));
            if (currency.get('space_btw_amount_and_symbol')) {
                res.push(' ');
            }
        }
        res.push(number);
        if (!currency.get('symbol_on_left')) {
            if (currency.get('space_btw_amount_and_symbol')) {
                res.push(' ');
            }
            res.push(currency.get('symbol'));
        }

        return res.join('');
    }

}