import {CollectionView} from "backbone.marionette";
import TopCompanyMapCompanyItemView from "./TopCompanyMapCompanyItemView";

const CompanyCollectionView = CollectionView.extend({

    childView: TopCompanyMapCompanyItemView,
    childViewOptions: function () {
        return {
            companyBaseUrl: this.getOption('companyBaseUrl'),
            companyPageUrl: this.getOption('companyPageUrl'),
            companyBookUrl: this.getOption('companyBookUrl'),
            imgDomain: this.getOption('imgDomain')
        };
    },

});

export default CompanyCollectionView;