import {Application} from "backbone.marionette";
import AppRouter from "marionette.approuter";
import ReviewsController from "./controller/ReviewsController";

const ReviewsModuleApp = Application.extend({

    onStart() {
        let controller = new ReviewsController({
            reviews: this.getOption('data').reviews,
            profile: this.getOption('data').profile,
            paths: this.getOption('paths'),
            captchaSiteKey: this.getOption('data').captchaSiteKey
        });
        new AppRouter({
            controller: controller,
            appRoutes: {
                'reviews(/:page)(/)': 'show'
            }
        });

        Backbone.history.start();

        controller.start();
    }

});

export default ReviewsModuleApp;