import _template from "../../../app/library/_template";
import {View} from "backbone.marionette";
import _ from "underscore";

import "./FilterViewPartials";
import "../../../app/library/marionette/view/helpers/Helpers";
import DateFilterUi from "../../../dom.ui/filter/DateFilter";
import TimeFilterUi from "../../../dom.ui/filter/TimeFilter";
import PriceFilterUi from "../../../dom.ui/filter/PriceFilter";

const SearchFilterModuleView = View.extend({

    template: _template('#app_filter_search_module_template'),

    events: {
        'click @ui.dropdownOpen': 'onShowFilters',
        'click @ui.dropdownClose': 'onHideFilters',
        'click @ui.filtersMobileOpen': 'onShowFiltersMobile',
        'click @ui.filtersMobileClose': 'onHideFiltersMobile',
        'click @ui.reset': 'reset'
    },

    ui: {
        'reset': '.app-reset',
        'form': '.app-form',
        'dropdownOpen': '.app-more',
        'dropdown': '.app-dropdown',
        'dropdownClose': '.app-close-dropdown',
        'filtersList': '.app-list',
        'filtersMobileOpen': '.app-toggle-list',
        'filtersMobileClose': '.app-close-list'
    },

    collectionEvents: {
        'sync': 'render'
    },

    reset: function () {
        this.ui.form.find(':input').val('').attr('name', null);
        this.ui.form.find(':checkbox').prop('checked', false);

        this.triggerSubmit();
    },

    serializeData: function () {
        let hasAttributes = false;
        let attributesCount = 0;
        this.collection.each((model, index) => {
            if (model.get('view_type') === 'link_select') {
                return;
            }
            // weird thing - we can get this attribute only this way.
            if (model.toJSON().is_attribute) {
                hasAttributes = true;
                attributesCount++;
            }
        });

        let attrClass = 'has_1-column';
        if (attributesCount >= 1) {
            attrClass = 'has_1-column';
        }
        if (attributesCount >= 2) {
            attrClass = 'has_2-column';
        }
        if (attributesCount >= 3) {
            attrClass = 'has_3-column';
        }
        if (attributesCount > 4) {
            attrClass = 'has_more-column';
        }

        return _.extend(View.prototype.serializeData.apply(this, arguments), {
            items: this.collection.toJSON(),
            has_attributes: hasAttributes,
            add_class: attrClass,
            currency: this.getOption('client_location').currency
        });
    },

    onDomRefresh: function () {
        new DateFilterUi(
            $('.app-datepicker'), $('.app-dateinput'), $('.app-datelabel'), $('.app-dateclear')
        );
        new TimeFilterUi(
            $('.app-timeselect-from'), $('.app-timeselect-to'), $('.app-timeslider'), $('.app-timelabel'), $('.app-timeclear')
        );
        if (this.getOption('client_location').currency) {
            new PriceFilterUi(
                $('.app-priceinput-from'), $('.app-priceinput-to'), $('.app-priceslider'), $('.app-pricelabel'), $('.app-priceclear'),
                this.getOption('client_location').currency
            );
        }
        $('.app-select-link-item').click((event) => {
            let target = $(event.target);
            $('.app-field-' + target.data('key')).val(target.data('value'));
            this.triggerSubmit();

            return false;
        });

        $('.app-slider').each((index, el) => {
            let root = $(el);
            let from = $('.app-slider-from', root);
            let to  = $('.app-slider-to', root);
            let slider = $('.app-slider-element', root);
            let handler = (value) => {
                from.val(value.from);
                to.val(value.to);
            };

            slider.ionRangeSlider({
                type: 'double',
                min: from.data('min'),
                max: to.data('max'),
                skin: 'round',
                hide_min_max: true,
                onChange: handler,
                onUpdate: handler
            });
            slider.data("ionRangeSlider").update({
                from: from.val() || from.data('min'),
                to: to.val() || to.data('max')
            });
        });

        const $pageOverlay = $('#dr_overlay');
        const $filtersOpen = this.getUI('dropdownOpen');
        const $filtersDropdown = this.getUI('dropdown');

        $pageOverlay.click(function () {
            $pageOverlay.removeClass('show');
            $filtersOpen.removeClass('active');
            $filtersDropdown.removeClass('show');
        })


    },

    onShowFilters () {
        const $pageOverlay = $('#dr_overlay');

        this.ui.dropdownOpen.toggleClass('active');
        this.ui.dropdown.toggleClass('show');
        $pageOverlay.toggleClass('show');
    },

    onHideFilters () {
        const $pageOverlay = $('#dr_overlay');

        this.ui.dropdownOpen.toggleClass('active');
        this.ui.dropdown.toggleClass('show');
        $pageOverlay.toggleClass('show');
    },

    onShowFiltersMobile () {
        this.ui.filtersList.toggleClass('active');
        $('html, body').toggleClass('hideBodyScroll');
        $('.filters__item .app-datelabel').trigger('click');
    },

    onHideFiltersMobile () {
        this.ui.filtersList.toggleClass('active');
        $('html, body').toggleClass('hideBodyScroll');
    },

    triggerSubmit () {
        this.ui.form.get(0).submit();
    }

});

export default SearchFilterModuleView;