import _template from "../app/library/_template";

export default class CategorySelectUi {

    el = null;
    id = '';
    template = _template('#app_ui_category_select');
    container = null;

    constructor(el, id) {
        this.el = $(el);
        if (id) {
            this.id = id;
        }

        this.initDom();
    }

    initDom() {
        this.el.hide();
        this.container = $('<div />');
        this.el.after(this.container);

        $(document).click((event) => {
            if ($(event.target).parents().closest(this.container).length > 0) {
                return null;
            }
            if ($('#app_cat_dropdown_' + this.id).is('.show')) {
                this.render();
            }
        });

        this.render();
    }

    render() {
        let groups = [];
        let selected = null;
        let val = this.el.val();

        this.el.find('optgroup').each((index, elGroup) => {
            let groupEl = $(elGroup);

            let group = {
                label: groupEl.attr('label'),
                key: groupEl.data('key'),
                value: groupEl.data('value'),
                children: []
            };
            if (val === groupEl.data('value')) {
                group.selected = true;
                selected = group;
            }

            groupEl.find('option').each((index, elOption) => {
                let optionEl = $(elOption);

                let option = {
                    label: optionEl.text(),
                    selected: false,
                    value: optionEl.attr('value')
                };
                group.children.push(option);

                if (val === optionEl.attr('value')) {
                    option.selected = true;
                    group.selected = true;
                    selected = option;
                }
            });

            groups.push(group);
        });

        let html = this.template({
            id: this.id,
            groups: groups,
            selected: selected,
            placeholder: this.el.data('placeholder')
        });

        this.container.html(html);

        this.initEvents();
    }

    initEvents() {
        this.container.find('.app-select-option').click((event) => {
            this.el.val($(event.target).data('value')).change();
            this.render();
        });

        $('body').removeClass('categories-opened');
        $('html').removeClass('categories-opened');
        $('#app_cat_dropdown_' + this.id).on('show.bs.collapse', () => {
            $('body').addClass('categories-opened');
            $('html').addClass('categories-opened');
        });

        $('#app_cat_dropdown_' + this.id).on('hide.bs.collapse', () => {
            $('body').removeClass('categories-opened');
            $('html').removeClass('categories-opened');
        });
    }

}