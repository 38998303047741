import _ from "underscore";
import Handlebars from "handlebars/dist/handlebars";
import "intl";
import CurrencyFormatter from "../../lib/CurrencyFormatter";

// var LANG = LANG || {};

Handlebars.registerHelper('in', function (arr, val) {
    return _.contains(arr, val) || _.contains(arr, '' + val);
});

Handlebars.registerHelper('eq', function (v1, v2) {
    return v1 == v2;
});

Handlebars.registerHelper('arrayIsEmpty', function (data) {
    return !(_.isArray(data) && data.length > 0);
});

Handlebars.registerHelper('default', function (val, defVal) {
    if (_.isUndefined(val) || _.isNull(val)) {
        return defVal;
    }
    return val;
});

Handlebars.registerHelper('in', function (v, a) {
    if (!_.isArray(a)) {
        return false;
    }
    return a.indexOf(v) > -1 || a.indexOf(v + '') > -1;
});

Handlebars.registerHelper('not', function (v) {
    return !v;
});

Handlebars.registerHelper('add', function (v1, v2) {
    return v1 + v2;
});

Handlebars.registerHelper('_t', function (key, options) {
    return _translate(key, options);
});

Handlebars.registerHelper('formatPrice', function (price, currency, options) {
    return CurrencyFormatter.getInstance().format(price, currency);
});

Handlebars.registerHelper('reviewRate', function (rate) {
    return Math.floor(rate * 2) / 2;
});

Handlebars.registerHelper('formatDatetime', function (datetimeStr, options) {
    return new Date(datetimeStr).toLocaleString();
});

Handlebars.registerHelper('moreThan', function (val1, val2, options) {
    return val1 > val2;
});

Handlebars.registerHelper('json', function (data, options) {
    return JSON.stringify(data);
});

Handlebars.registerHelper('ios', function (options) {
    return true;
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
});

Handlebars.registerHelper('formatEventDuration', function (duration) {
    let shortHourText = _translate('short_hour', {});
    let shortMinuteText = _translate('short_minute', {});
    let formattedTime = '';

    if (duration < 60) {
        formattedTime = duration + shortMinuteText;
    } else {
        let hours = Math.floor(duration / 60);
        duration = (duration % 60);

        formattedTime = duration
            ? hours + shortHourText + ' ' + duration + shortMinuteText
            : hours + shortHourText;
    }

    return formattedTime;
});

Handlebars.registerHelper('for', function (options) {
    let out = '';
    for (let i = options.hash.from; i <= options.hash.to; i += options.hash.step || 1) {
        out += options.fn(i);
    }
    return out;
});

Handlebars.registerHelper('formatHourTime', function (val, options) {
    if (val < 10) {
        val = '0' + val;
    }
    return val + ':00';
});

Handlebars.registerHelper('item', function (items, field, value, options) {
    return _.find(items, (obj) => {
        return obj[field] === value;
    });
});

Handlebars.registerHelper('img', function (img) {
    return '/build/' + img;
});

Handlebars.registerHelper('lower', function(str) {
    return str.toLowerCase();
});

Handlebars.registerHelper('buildPlaceUrlPart', function(id) {
    let data = id.split('.');

    let map = {
        'place': 'city',
        'region': 'state',
        'country': 'country',
        'postcode': 'postcode',
    };
    return map[data[0]] + '/' + parseInt(data[1], 10).toString(36);
});

Handlebars.registerHelper('concat', function() {
    var result = '';
    _.each(arguments, function (substr) {
        if (_.isString(substr) || _.isNumber(substr)) {
            result += substr;
        }
    });

    return result;
});

Handlebars.registerHelper('ifEmpty', function(value, options) {
    if (value && value.trim().length > 0) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

// ----- debug -----
Handlebars.registerHelper('debug', function() {
    console.log('----- debug -----');
    _.each(this, function (value, key) {
        console.log(key, ' = ', value);
    });
});


function _translate(key, options) {
    let debugLang = false;
    let str = key;
    if (!_.isUndefined(LANG) && LANG[key]) {
        str = LANG[key];
    } else if (debugLang) {
        console.warn('Lang key missing', key);
    }

    let data = options.hash;
    _.each(data, (v, k) => {
        str = (str + '').replace('%' + k + '%', v);
    });
    return str;
}