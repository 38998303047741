export default class AbstractMap {

    /**
     * Attach map
     *
     * @param el
     * @param options
     */
    attachMap(el, options) {
        throw new Error('Please override attachMap method');
    }

    /**
     * Clear all markers
     */
    clearMarkers() {
        throw new Error('Please override clearMarkers method');
    }

    /**
     * Add marker
     *
     * @param lat
     * @param lng
     * @param id
     * @param data
     */
    addMarker(lat, lng, id, data) {
        throw new Error('Please override addMarker method');
    }

    /**
     * High light marker by id
     *
     * @param id
     */
    highlightMarker(id) {
        throw new Error('Please override highlightMarker method');
    }

    /**
     * Unhigh light marker by id
     *
     * @param id
     */
    unHighlightMarker(id) {
        throw new Error('Please override unHighlightMarker method');
    }

    /**
     * Unhigh light markers
     */
    unHighlightMarkers() {
        throw new Error('Please override unHighlightMarker method');
    }

    /**
     * Add popup
     *
     * @param lat
     * @param lng
     * @param element
     */
    makePopup(lat, lng, element) {
        throw new Error('Please override makePopup method');
    }

    /**
     * Add cluster
     *
     * @param lat
     * @param lng
     * @param count
     */
    addCluster(lat, lng, count) {
        throw new Error('Please override addCluster method');
    }

    /**
     * Invalidate size of map
     */
    invalidateSize() {
        throw new Error('Please override invalidateSize method');
    }

    /**
     * Return max zoom for this map type
     */
    getMaxZoom() {
        throw new Error('Please override getMaxZoom method');
    }

    /**
     * Fit bounds to markers
     */
    fitBounds() {
        throw new Error('Please override fitBOunds method');
    }

}