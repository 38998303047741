import {Application} from "backbone.marionette";
import MapFilterController from "./controller/MapFIlterController";
import MapController from "./controller/MapController";
import CurrencyFormatter from "../../app/library/marionette/lib/CurrencyFormatter";

const SearchFilterModuleApp = Application.extend({

    filterController: null,
    mapController: null,

    onStart() {
        CurrencyFormatter.getInstance().init({url: this.getOption('paths').currency_end_point}).then(() => {
            this.filterController = new MapFilterController({
                paths: this.getOption('paths'),
                data: this.getOption('data')
            });
            this.filterController.start();

            this.mapController = new MapController({
                paths: this.getOption('paths'),
                data: this.getOption('data'),
                center: this.getOption('map_center'),
                bound: this.getOption('map_bound')
            });

            this.mapController.setFilter(this.getOption('data').filter);
            this.listenTo(this.filterController, 'filter:changed', (params) => {
                this.mapController.setFilter(params);
            });
            this.mapController.start();
        });
    }

});

export default SearchFilterModuleApp;