import {View} from "backbone.marionette";
import _ from "underscore";
import _template from "../../../app/library/_template";

const CompanyNextPageButtonView = View.extend({

    template: _template('#app_company_next_page_button_view'),
    ui: {
        'button': '#app_next_page_button'
    },

    paginator: null,
    observer: null,

    initialize() {
        this.paginator = this.collection.getPaginator();

        this.listenTo(this.paginator, 'update:info:count', this.render);

        this.observer = new IntersectionObserver((data) => {
            _.each(data, (element) => {
                if (element.isIntersecting) {
                    if (this.paginator.getPage() < this.paginator.getPagesCount()) {
                        this.triggerMethod('next:page');
                    }
                    this.observer.unobserve(element.target);
                }
            });
        });
    },

    onDomRefresh() {
        let el = this.ui.button.get(0);
        if (el) {
            this.observer.observe(el);
        }
    },

    serializeData() {
        return _.extend(View.prototype.serializeData.apply(this, arguments), {
            next_page_allowed: this.paginator.getPage() < this.paginator.getPagesCount()
        });
    }

});

export default CompanyNextPageButtonView;